import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useChannelStateContext, useChatContext } from "stream-chat-react";
import { UserAuth } from "../Context";

import { Card } from "react-bootstrap";
import ProfilePicture from "../UserProfile/ProfilePicture";
import useChatClient from "../StatesStore/ChatClient";
export const ChannelHeader = ({ setIsChatOpen }) => {
  const { user } = UserAuth();
  const { channel } = useChannelStateContext();
  const [channelUsers, setChannelUsers] = useState([]);
  const { client } = useChatContext();

  // Update the list of channel users dynamically
  useEffect(() => {
    const updateChannelUsers = (event) => {
      if (!channel?.state) return;

      setChannelUsers(
        Object.values(channel.state.members).map((member) => ({
          id: member.user_id,
          name: member.user.name || "Unknown User",
          online: member.user.online || false,
          photo: member.user.image || "", // Assuming avatars are available
        }))
      );
    };

    // Initial update
    updateChannelUsers();

    // Listen for real-time presence changes
    client.on("user.presence.changed", updateChannelUsers);

    // Cleanup listener on unmount
    return () => {
      client.off("user.presence.changed", updateChannelUsers);
    };
  }, [channel, client]);

  if (!channel || !user) {
    return null;
  }

  return (
    <Card.Header className="d-sm-flex justify-content-between align-items-center py-2 px-2">
      <div className="d-flex mb-2 align-items-center">
        <button
          onClick={() => setIsChatOpen(false)}
          className="btn me-2 p-1 d-md-none"
        >
          <i className="fa-solid fa-arrow-left"></i>
        </button>
        {channel.type === "messaging" && channelUsers.length === 2 ? (
          // Private messaging channel with two members
          <Link
            to={`/userProfile/${
              channelUsers.find((member) => member.id !== user?.uid)?.id
            }`}
            className="d-flex align-items-center"
          >
            <div className="flex-shrink-0 avatar me-2">
              <ProfilePicture
                userData={channelUsers.find(
                  (member) => member.id !== user?.uid
                )}
              />
            </div>
            <div className="d-block flex-grow-1">
              <h6 className="mb-0 mt-1">
                {channelUsers.find((member) => member.id !== user?.uid)?.name ||
                  "User"}
              </h6>
              <div className="small text-secondary">
                <i
                  className={`fa-solid fa-circle ${
                    channelUsers.find((member) => member.id !== user?.uid)
                      ?.online
                      ? "text-success"
                      : "text-muted"
                  } me-1`}
                ></i>
                {channelUsers.find((member) => member.id !== user?.uid)?.online
                  ? "Online"
                  : "Offline"}
              </div>
            </div>
          </Link>
        ) : (
          <div className="d-flex">
            {channelUsers.length === 1 ? (
              channelUsers.map((member) => (
                <div key={member.id} className="flex-shrink-0 avatar me-2">
                  <ProfilePicture userData={member} />
                </div>
              ))
            ) : (
              <ul
                className={`mb-0 avatar-group avatar-group-${
                  channelUsers.length === 2 ? "two" : "three"
                }`}
              >
                {channelUsers.slice(0, 3).map((member) => (
                  <li key={member.id} className="avatar avatar-xs">
                    <ProfilePicture userData={member} />
                  </li>
                ))}
                {/* <li className="avatar avatar-xs">+{channelUsers.length - 3}</li> */}
              </ul>
            )}
            <div className="d-block ps-2">
              <h6 className="mb-0 mt-1">{channel.data.name || "Group Chat"}</h6>
              <h6 className="mb-0 fw-light">
                {channelUsers
                  .map((member) => member.name.split(" ")[0])
                  .join(", ")}
              </h6>
            </div>
          </div>
        )}
      </div>
    </Card.Header>
  );
};

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Chat,
  Channel,
  MessageInput,
  MessageList,
  Window,
  ChannelList,
  Attachment,
  defaultRenderMessages,
  useChannelStateContext,
} from "stream-chat-react";
import useChatClient from "../StatesStore/ChatClient";
import "stream-chat-react/dist/css/v2/index.css";
import { UserAuth } from "../Context";
import { Border, List } from "react-bootstrap-icons";
import { EmojiPicker } from "stream-chat-react/emojis";
import { encodeToMp3 } from "stream-chat-react/mp3-encoder";
import "./Chat.css";
import SideBar from "./SideBar";
import ProductCard3 from "../Products/ProductCard3";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import { ChannelHeader } from "./ChannelHeader";
import CustomMessageInput from "./CustomMessageInput";

function Chat2() {
  const [loading, setLoading] = useState(true);
  const { user } = UserAuth();
  const navigate = useNavigate();
  const { chatId } = useParams();
  const client = useChatClient((state) => state.client);

  if (!client) {
    return <LoadingSpinner />;
  }

  const containerStyle = {
    display: "flex",
    height: "84vh",
    width: "100%",
    overflow: "hidden",
    gap: "0.5rem",
  };

  const channelListStyle = {
    flex: 1,
    maxWidth: "300px",
    borderLeft: "1px solid rounded",
    overflowY: "auto",
    backgroundColor: "#f8f9fa",
    borderRadius: "4px 0 0 4px",
  };

  const channelWindowStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    borderRadius: "0px 4px 4px 0px",
  };
  const filters = {
    members: { $in: [user?.uid] },
    // type: 'messaging'
  };

  const sort = { last_message_at: -1 };

  const options = {
    state: true,
    presence: true,
    limit: 10,
  };
  const customRenderText = (text) => {
    return <span>{text}</span>;
  };

  const CustomAttachment = (props) => {
    const { attachments } = props;
    const [attachment] = attachments || [];
    if (attachment?.type === "product") {
      return <ProductCard3 product={attachment?.product} isStrip={false} />;
    }

    return <Attachment {...props} />;
  };
  const customRenderMessages = (options) => {
    const { messages, internalMessageProps, groupedMessages } = options;
    const elements = [];

    messages.forEach((message) => {
      if (message.product) {
        elements.push(
          <li key={message.id} className="w-25 h-25">
            <ProductCard3 product={message?.product} isStrip={false} />
          </li>
        );
      } else {
        elements.push(
          defaultRenderMessages({ ...options, messages: [message] })
        );
      }
    });

    return elements;
  };

  const WrappedMessageList = () => (
    <MessageList
      renderText={customRenderText}
      closeReactionSelectorOnClick={true}
    />
  );
  if (!user) return;
  return (
    <>
      <style>
        {`
        .str-chat {
          --str-chat__border-radius-circle: 4px; 
          --str-chat__avatar-background-color: #dddddd;
          --str-chat__avatar-color:#222222;
           --str-chat__channel-preview-active-background-color: #e7f0fd;
        }
        .str-chat__li{
           padding-inline: 0.5rem !important;
        }
           
      `}
      </style>
      {/* <button
        className="border-0 rounded-2 me-2 icon-md btn btn-light p-0 position-absolute z-1 d-lg-none mb-4"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasSideChatbar"
        aria-controls="offcanvasSideChatbar"
      >
        <span className="btn btn-primary p-2">
          <List className="fs-4" />
        </span>
      </button> */}
      <Chat client={client} theme="messaging light">
        <div style={containerStyle}>
          <SideBar />

          <div style={channelWindowStyle} className="rounded-2 border">
            <Channel EmojiPicker={EmojiPicker} Attachment={CustomAttachment}>
              <Window>
                {/* <Users /> */}
                <ChannelHeader />
                {/* <ChannelHeader /> */}
                <MessageList
                  messageActions={["edit", "delete", "quote", "react"]}
                  closeReactionSelectorOnClick={true}
                />
                <CustomMessageInput
                  audioRecordingConfig={{
                    transcoderConfig: { encoder: encodeToMp3 },
                  }}
                  audioRecordingEnabled
                />
              </Window>
            </Channel>
          </div>
        </div>
      </Chat>
    </>
  );
}

export default Chat2;

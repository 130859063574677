import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  addDoc,
  updateDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  increment,
  setDoc,
} from "firebase/firestore";
import { db, storage } from "../FirebaseConfig";
import useModalStore from "../StatesStore/ModalStore";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import { Dropdown, Modal, Form, Row, Col, Button } from "react-bootstrap";
import { Upload, Progress } from "antd";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import { v4 } from "uuid";

const ListShareMenu = ({
  listName,
  myName,
  listLink,
  listId,
  userId,
  products,
  componentName,
}) => {
  const { openModal } = useModalStore();
  const [content, setContent] = useState("");
  const [show, setShow] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoList, setVideoList] = useState([]);
  const [textContent, setTextContent] = useState("");
  console.log("products from list sharemenu", products);

  const handleClose = () => setShow(false);
  // Function to handle copying the link to clipboard
  const handleCopyLink = async () => {
    try {
      if (products.length === 0) {
        toast.warn(
          "You cannot copy an empty list link. Please add some products first.",
          toastOptions
        );
        return;
      }
      await navigator.clipboard.writeText(listLink);
      toast.success("Link copied successfully", toastOptions);
    } catch (error) {
      console.error("Failed to copy link: ", error);
    }
  };
  const handleFacebookShare = (url, quote) => {
    // Open Facebook with the share URL and title
    const shareUrl = encodeURIComponent(url);
    const shareQuote = encodeURIComponent(quote);

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${shareQuote}`
    );
  };

  const handleWhatsappShare = (listLink, content) => {
    // Open WhatsApp with the share URL and title
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(`${listLink}`)}`
    );
  };
  const handleInstagramShare = (url, content) => {
    window.open(
      `https://www.instagram.com/direct/new/?text=${encodeURIComponent(url)}`
    );
  };

  const handleSnapchatShare = (url, content) => {
    const shareUrl = encodeURIComponent(url);
    const shareQuote = encodeURIComponent(content);

    window.open(`https://www.snapchat.com/scan?text=${shareUrl} ${shareQuote}`);
  };

  const handleTwitterShare = (title, url) => {
    const shareUrl = encodeURIComponent(url);
    const shareTitle = encodeURIComponent(title || `Check out ${listName}`);
    window.open(
      `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}`
    );
  };

  const handleEmailShare = (title, shareUrl) => {
    // Open default email client with the share URL and title
    // window.location.href = `mailto:?subject=${encodeURIComponent(
    //   title
    // )}&body=${encodeURIComponent(shareUrl)}`;
    const DATA = {
      myName,
      shareURL: listLink,
      listName,
    };
    openModal("ShoppingListInvite", "", DATA);
  };

  // Define the social media sharing options
  const socialMediaOptions = [
    {
      name: "facebook",
      label: " Share via Facebook",
      icon: (
        <i
          className="bi bi-facebook fa-fw"
          style={{ fontSize: "1.3rem", color: "#0057DA" }}
        ></i>
      ),
      handler: (url, quote) =>
        handleSocialShare(() => handleFacebookShare(url, quote)),
    },
    {
      name: "whatsapp",
      label: " Share via WhatsApp",
      icon: (
        <i
          className="bi bi-whatsapp fa-fw"
          style={{ fontSize: "1.3rem", color: "#26d367" }}
        ></i>
      ),
      handler: (url, content) =>
        handleSocialShare(() => handleWhatsappShare(url, content)),
    },
    // {
    //   name: "email",
    //   label: " Share via Email",
    //   icon: (
    //     <i
    //       className="bi bi-envelope fa-fw"
    //       style={{ fontSize: "18px", color: "#EE3900" }}
    //     ></i>
    //   ),
    //   handler: (title, url) =>
    //     handleSocialShare(() => handleEmailShare(title, url)),
    // },
    {
      name: "instagram",
      label: " Share via Instagram",
      icon: (
        <i
          className="bi bi-instagram fa-fw"
          style={{ fontSize: "1.3rem", color: "#E1306C" }}
        ></i>
      ),
      handler: (url, content) =>
        handleSocialShare(() => handleInstagramShare(url, content)),
    },
    // {
    //   name: "snapchat",
    //   label: " Share via Snapchat",
    //   icon: (
    //     <i
    //       className="bi bi-snapchat fa-fw"
    //       style={{ fontSize: "18px", color: "#FFFC00" }}
    //     ></i>
    //   ),
    //   handler: (url, content) =>
    //     handleSocialShare(() => handleSnapchatShare(url, content)),
    // },
    {
      name: "twitter",
      label: " Share via Twitter",
      icon: (
        <i
          className="bi bi-twitter fa-fw"
          style={{ fontSize: "1.3rem", color: "#1DA1F2" }}
        ></i>
      ),
      handler: (title, url) =>
        handleSocialShare(() => handleTwitterShare(title, url)),
    },
  ];
  const handleRemove = (file, setFileList) => {
    setFileList((prevFileList) =>
      prevFileList.filter((item) => item.uid !== file.uid)
    );
  };

  const uploadFiles = async () => {
    let promises = [];
    let totalFiles = fileList.length + videoList.length;
    let completedFiles = 0;

    const updateProgress = () => {
      completedFiles += 1;
      setUploadProgress((completedFiles / totalFiles) * 100);
    };
    if (fileList.length === 0 && videoList.length === 0 && textContent) {
      // Handle text-only post

      setTextContent("");
      return;
    }
    if (fileList.length > 0) {
      const imagePromises = fileList.map((file) => {
        return new Promise((resolve, reject) => {
          const imageRef = ref(
            storage,
            `customPosts/images/${file.name + v4()}`
          );
          const uploadTask = uploadBytesResumable(imageRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.error("Error uploading image:", error);
              reject(error);
            },
            async () => {
              const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve({ type: "image", url: imageUrl });
              updateProgress();
            }
          );
        });
      });
      promises.push(...imagePromises);
    }

    if (videoList.length > 0) {
      const videoPromises = videoList.map((file) => {
        return new Promise((resolve, reject) => {
          const videoRef = ref(
            storage,
            `customPosts/videos/${file.name + v4()}`
          );
          const uploadTask = uploadBytesResumable(videoRef, file);

          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.error("Error uploading video:", error);
              reject(error);
            },
            async () => {
              const videoUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve({ type: "video", url: videoUrl });
              updateProgress();
            }
          );
        });
      });
      promises.push(...videoPromises);
    }

    if (promises.length > 0) {
      try {
        const uploadedFiles = await Promise.all(promises);
        console.log("uploadedFiles", uploadedFiles);
        handleProfileShare(uploadedFiles);
        setUploadProgress(0);
      } catch (error) {
        console.error("Error uploading files:", error);
        setUploadProgress(0);
      }
    } else {
      handleProfileShare([]);
      console.log("No files to upload.");
    }
  };

  const handleProfileShare = async (mediaDocs) => {
    try {
      const shoppingListRef = doc(
        db,
        "users",
        userId,
        "shoppingList",
        listName
      );
      const productsRef = collection(shoppingListRef, "products");
      const productsSnapshot = await getDocs(productsRef);

      // Get all product document IDs
      const products = productsSnapshot.docs.map((doc) => doc.id);
      console.log("Products in list:", products);

      // Check if products exist
      if (products.length === 0) {
        console.error("No products found in the list.");
        toast.warn(
          `You cannot share an empty list. Please ad some products first.`,
          toastOptions
        );
        return;
      }
      // const thumbnailUrl = mediaDocs.length > 0 ? mediaDocs[0].url : null;
      // Use the uploaded thumbnail or fallback to the first product's image
      let thumbnailUrl = mediaDocs.length
        ? mediaDocs[0].url
        : products.length > 0
        ? await getProductImageUrl(products[0])
        : null;

      // Create a new post object
      const newPost = {
        dateCreated: serverTimestamp(),
        title: title || `Check out ${listName} list`,
        description: description || ``,
        by: "user",
        userId: userId,
        thumbnailUrl: thumbnailUrl,
        type: "image",
        postType: "shoppingList",
        listId: listId,
        listName: listName,
      };
      console.log("newPost", newPost);
      const postDocRef = await addDoc(collection(db, "posts"), newPost);

      // Save the media for each product
      for (const productId of products) {
        const productDocRef = doc(db, "products", productId);
        const productDoc = await getDoc(productDocRef);

        if (productDoc.exists()) {
          const productData = productDoc.data();
          const url = productData.imageUrl || [];

          const mediaDocRef = doc(postDocRef, "media", productDoc?.id);
          await setDoc(mediaDocRef, { url });
        }
      }

      await updateDoc(doc(db, "users", userId, "shoppingList", listName), {
        visibility: "Public",
      });

      await updateDoc(doc(db, "users", userId), {
        posts: increment(1),
      });
      setTitle("");
      setDescription("");
      setFileList([]);
      toast.success(`${listName} list shared successfully`, toastOptions);
    } catch (error) {
      console.error("Error adding post:", error);
    }
  };

  const getProductImageUrl = async (productId) => {
    const productDocRef = doc(db, "products", productId);
    const productDoc = await getDoc(productDocRef);

    if (productDoc.exists()) {
      const productData = productDoc.data();
      return productData.imageUrl || null;
    }
    return null;
  };

  const handleQuoteChange = (event) => {
    setContent(event.target.value);
  };
  const handleSocialShare = (handler) => {
    if (products.length === 0) {
      toast.warn(
        "You cannot share an empty list. Please add some products first.",
        toastOptions
      );
    } else {
      handler(listLink, content);
    }
  };
  return (
    <>
      {/* <Dropdown className="nav-item my-2" drop="down-centered">
        <Dropdown.Toggle as="span" className="">
          <i
            className="nav-link icon-md btn btn-light p-0 bi bi-share-fill fs-6"
            aria-hidden="true"
          ></i>
          <span className="mx-2 d-md-none h6 fw-light">Share</span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end mt-0 p-2">
          <div className="w-100 d-flex justify-content-between">
            {socialMediaOptions.map((option, index) => (
              <div key={index}>
                <Dropdown.Item
                  className="p-2"
                  to=""
                  onClick={() => option.handler(listLink, content)}
                >
                  {option.icon}
                </Dropdown.Item>
              </div>
            ))}
          </div>
          <li>
            <Dropdown.Item
              className="p-2 fs-5 d-flex gap-1 ps-1 align-items-center"
              to=""
              onClick={() => setShow(true)}
            >
              <i
                className="bi bi-person-circle fa-fw"
                style={{
                  marginRight: "4px",
                }}
              ></i>
              <span style={{ fontSize: "15px" }}>Post on Lookflock</span>
            </Dropdown.Item>
            <hr className="dropdown-divider m-0" />
          </li>
          <Dropdown.Item className="p-0">
            <div className="d-flex justify-content-center mt-2">
              <button
                className=" btn btn-sm btn-primary-soft "
                onClick={() => handleCopyLink()}
              >
                <i class="bi bi-link-45deg"></i> Copy Link
              </button>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
      <span
        className="nav-link icon-md btn btn-light p-0"
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="bi bi-share-fill fs-6"></i>
      </span>
      {componentName !== "ProfilePage" && (
        <span className="mx-2 d-md-none h6 fw-light">Share</span>
      )}
      <div className="dropdown">
        {/* Dropdown menu */}
        <ul
          className="dropdown-menu dropdown-menu-end mt-0 p-2"
          aria-labelledby="dropdownMenuLink"
        >
          <div className="w-100 d-flex justify-content-between">
            {/* Dynamically render social media options */}
            {socialMediaOptions.map((option, index) => (
              <div key={index}>
                <div
                  style={{ cursor: "pointer" }}
                  className="dropdown-item p-2"
                  onClick={() => option.handler(listLink, content)}
                >
                  {option.icon}
                </div>
              </div>
            ))}
          </div>
          <li>
            <div
              className="dropdown-item p-2 fs-5 d-flex gap-1 ps-1 align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (products?.length === 0) {
                  toast.warn(
                    "Please add some products in the list before posting",
                    toastOptions
                  );
                } else {
                  setShow(true);
                }
              }}
            >
              <i
                className="bi bi-person-circle fa-fw"
                style={{ marginRight: "4px" }}
              ></i>
              <span style={{ fontSize: "15px" }}>Post on Lookflock</span>
            </div>
            <hr className="dropdown-divider m-0" />
          </li>
          <li className="dropdown-item p-0">
            <div className="d-flex justify-content-center mt-2">
              <button
                className="btn btn-sm btn-primary-soft"
                onClick={handleCopyLink}
              >
                <i className="bi bi-link-45deg"></i> Copy Link
              </button>
            </div>
          </li>
        </ul>
      </div>

      <Modal show={show} onHide={handleClose} centered scrollable>
        <Modal.Header closeButton>
          <Modal.Title id="modalLabelCreateFeed">Create Post</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {/* <!-- Title --> */}
          <div className="mb-3">
            <label className="form-label">Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Add title here"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Description</label>
            <textarea
              className="form-control"
              rows="3"
              placeholder="Add description here"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <div>
            <label className="form-label">Upload thumbnail</label>
            <Upload
              listType="picture"
              accept=".png,.jpg,.jpeg,.webp"
              className="dropzone dropzone-default card shadow-none"
              maxCount={1}
              multiple={true}
              fileList={fileList}
              // showUploadList={fileList.length===0?false:true}
              onRemove={(file) => handleRemove(file, setFileList)}
              beforeUpload={(file) => {
                setFileList((prevFileList) => [...prevFileList, file]);
                return false;
              }}
              style={{ backgroundColor: "#333", color: "white" }}
            >
              <div className="dz-message">
                <i className="bi bi-images display-3"></i>
                <p>Drag here or click to upload thumbnail.</p>
              </div>
            </Upload>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Row className="w-100 justify-content-between">
            <Col lg={3}>
              {/* Uncomment and use this section if you need to include a select option */}
              {/* <Form.Select className="js-choice choice-select-text-none">
              <option value="PB">Public</option>
              <option value="PV">Friends</option>
              <option value="PV">Only me</option>
              <option value="PV">Custom</option>
            </Form.Select> */}
            </Col>
            <Col lg={8} className="text-sm-end">
              <button
                onClick={() => {
                  uploadFiles();
                  handleClose();
                }}
                type="button"
                className="btn btn-primary-soft"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Post
              </button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListShareMenu;

import React, { useState, useEffect } from "react";
import WebFont from "webfontloader";
import TermsAndConditions from "./TermsAndConditions";
import useOobCodeState from "../StatesStore/OobCode";
import useApiKeyState from "../StatesStore/ApiKey";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import { auth, db, facebookProvider, googleProvider } from "../FirebaseConfig";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import {
  applyActionCode,
  checkActionCode,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  deleteUser,
} from "firebase/auth";
import { UserAuth } from "../Context";
import {
  deleteDoc,
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { WelcomeUser } from "../EmailSystem/sendEmails";
import { Accordion } from "react-bootstrap";
import useUserStore from "../StatesStore/UserStore";
import { generateReferralCode } from "../lib/generateReferralCode";
import { handleInvite } from "../Referral/HandleInvite";
import usePointsStore from "../StatesStore/PointsTable";
import useUrlState from "../StatesStore/CurrentURL";
function VerifyEmail() {
  const navigate = useNavigate();
  const oobCode = useOobCodeState((state) => state.oobCode);
  const apiKey = useApiKeyState((state) => state.apikey);
  const [message, setMessage] = useState("");
  const { user } = UserAuth();
  const { setUser, createId } = useUserStore(); //for storing user
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const [errorConfirmPass, setErrorConfirmPass] = useState("");
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("ref");
  const pointsTable = usePointsStore((state) => state.pointsTable);
  const url = useUrlState((state) => state.url);
  const redirectUser = useNavigate();
  googleProvider.addScope("email");
  facebookProvider.addScope("email");
  const googleLogoPath =
    process.env.PUBLIC_URL + "/assets/images/logo/google-logo.svg";
  const facebookLogoPath =
    process.env.PUBLIC_URL + "/assets/images/logo/facebook-logo.svg";

  useEffect(() => {
    const setTextMessage = async () => {
      if (!user) return;
      const userRef = doc(db, "users", user?.uid);
      const userSnap = await getDoc(userRef);
      setMessage(`Hi ${userSnap?.data()?.userName ||
        user?.displayName ||
        user?.providerData[0].displayName ||
        ""}, Please verify your email address by
              clicking the link sent to ${userSnap?.data()?.email ||
                user?.email ||
                user?.providerData[0]?.email}`);
    };
    WebFont.load({
      google: {
        families: ["Nunito Sans", "sans-serif"],
      },
    });
    console.log(user);
    if (oobCode && apiKey) {
      checkActionCode(auth, oobCode)
        .then((info) => {
          // Email verification code is valid
          // Apply the action code to complete the email verification
          applyActionCode(auth, oobCode)
            .then(async () => {
              try {
                console.log("VERIFYEMAILUSER", user);
                const userRef = doc(db, "users", user?.uid);
                await updateDoc(userRef, { isVerified: true });
                user.emailVerified = true;
                const usersToBeDeletedRef = doc(
                  db,
                  "usersToBeDeleted",
                  user?.uid
                );
                const usersToBeDeletedSnap = await getDoc(usersToBeDeletedRef);
                if (usersToBeDeletedSnap.exists()) {
                  await deleteDoc(usersToBeDeletedRef);
                }
                toast.success("Email verified successfully", toastOptions);
                setMessage("Email verified successfully!");
                await WelcomeUser(user?.displayName, user.email);
              } catch (error) {
                console.error(error);
              }
              // Email verified successfully
              console.log("Email verified successfully!");
              // You can now sign in the user or redirect them to the login page
              if (user) navigate("/SignInInput");
              else navigate("/SignIn");
            })
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              setMessage(
                `Error verifying email in applying: ${errorMessage} ${errorCode}`
              );
              // console.error("Error applying action code:", errorCode, errorMessage);
            });
        })
        .catch((error) => {
          // Invalid action code
          const errorMessage = error.message;
          setMessage(`Error verifying email in checking: ${errorMessage}`);
          // console.error("Invalid action code:", errorCode, errorMessage);
        });
    } else {
      setTextMessage();
    }
    // eslint-disable-next-line
  }, [oobCode, apiKey]);
  useEffect(() => {
    if (user?.emailVerified) navigate("/");
    else if (!user?.emailVerified) navigate("/VerifyEmail");
  }, [user]);
  useEffect(() => {
    if (!user && !oobCode && !apiKey) {
      console.log("Got nothing byee");
      navigate("/");
    }
  }, [user]);

  const resendEmailVerification = async () => {
    await sendEmailVerification(user);
    toast.success("Check your inbox for email verification link", toastOptions);
  };
  const changeEmailAddress = async () => {
    // const usersToBeDeletedRef = doc(db, "usersToBeDeleted", user?.uid);
    // await setDoc(usersToBeDeletedRef, {
    //   email: user?.email,
    //   timestamp: serverTimestamp(),
    // });
    const userRef = doc(db, "users", user.uid);
    const userSnap = await getDoc(userRef);
    let oldUserData = userSnap.data();
    await deleteDoc(userRef);
    await deleteUser(user);
    oldUserData.email = email;
    console.log(oldUserData);
    try {
      const authUser = await createUserWithEmailAndPassword(auth, email, pass);
      await updateProfile(authUser.user, {
        displayName: oldUserData?.userName,
      });
      const userDocRef = doc(db, "users", authUser.user.uid);
      await setDoc(userDocRef, { ...oldUserData });
      await setUser(oldUserData);
      createId(authUser.user.uid);
      await sendEmailVerification(authUser.user)
        .then(() => {
          // Email verification sent!
          toast.success(
            "Email Updated Successfully. Check your inbox for email verification link",
            toastOptions
          );
          setMessage(`Hi ${oldUserData?.userName ||
            ""}, Please verify your email address by
                clicking the link sent to ${email ||
                  authUser.user?.providerData[0]?.email}`);

          // navigate("/VerifyEmail");
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(
            "Error sending email verification:",
            errorCode,
            errorMessage,
            toastOptions
          );
        });
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setErrorEmail(
          "Email address already in use. Please enter another email address."
        );
        return;
      }
    }
    setEmail("");
    setPass("");
    setConfirmPass("");
    try {
      // Show success message
      // toast.success("Email Updated Successfully", toastOptions);
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        toast.error("Incorrect Password. Please try again.");
      } else if (error.code === "auth/too-many-requests") {
        toast.error("Too many attempts. Please try again later.");
      } else {
        toast.error("Error Updating Email: " + error.message);
      }
    }
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
    console.log("email", email);
    console.log(user?.uid);
    console.log("user?.email", user?.providerData[0]?.email);
    if (email === (user?.email || user?.providerData[0]?.email)) {
      setErrorEmail("New email cannot be same as old email");
      return;
    }
    if (pass !== confirmPass) {
      setErrorConfirmPass("Password and Confirm Password do not match");
      return;
    } else if (pass.length < 6) {
      setErrorPass("Password must be at least 6 characters long");
      return;
    } else {
      changeEmailAddress();
    }
  };
  const googleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("result", result);
      return result;
    } catch (error) {
      throw error;
    }
  };
  const facebookSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      console.log(result.user);
      return result;
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        const pendingCred = FacebookAuthProvider.credentialFromError(error);
        const email = error.customData.email;
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        console.log(signInMethods);
        if (signInMethods.includes("google.com")) {
          try {
            alert(
              "Account with this email " +
                email +
                " already exists. You need to login with gmail first in order to link both accounts"
            );
            const userCredential = await signInWithPopup(auth, googleProvider);
            await linkWithCredential(userCredential.user, pendingCred);
            console.log("Successfully linked existing account with Facebook");
          } catch (linkError) {
            console.error("Error linking credential:", linkError);
          }
        } else if (signInMethods.includes("password")) {
          try {
            const password = prompt(
              "Account with this email " +
                email +
                " already existed. Enter you password in order to link both accounts:"
            );
            const userCredential = await signInWithEmailAndPassword(
              auth,
              email,
              password
            ); // No password needed
            await linkWithCredential(userCredential.user, pendingCred);
            console.log("Successfully linked existing account with Facebook");
          } catch (linkError) {
            console.error("Error linking credential:", linkError);
          }
        } else {
          console.log("Other sign-in methods detected:", signInMethods);
          // Handle other provider linking if needed
        }
      } else {
        console.error("Error during sign-in:", error);
      }
    }
  };
  const handleGoogleSignIn = async () => {
    try {
      const result = await googleSignIn();
      // Check if the result is defined and has a 'user' property
      if (result && result.user) {
        // Extract user information from the Google sign-in result
        const { user } = result;
        if (user) {
          console.log("user google", user);
          // console.log(user.emailVerified);
          user.emailVerified = true;
          user.email = user.providerData[0].email;
          // console.log("BEFORE CREATING ID");
          createId(user.uid);
          if (!user.displayName) {
            const displayName = "DefaultDisplayName";
            await updateProfile(user, { displayName });
          }

          // Store user information in Firestore
          const db = getFirestore();
          const userRef = doc(db, "users", user.uid);

          // Check if the user already exists in Firestore
          const docSnap = await getDoc(userRef);
          console.log("here ser google", user);
          //new user
          if (!docSnap.exists()) {
            await WelcomeUser(user.displayName, user.providerData[0]?.email);
            console.log("BEFORE SETING IT TRUE");
            auth.currentUser.emailVerified = true;
            fetch(process.env.REACT_APP_API + "/api/updateEmailVerified", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ uid: user.uid }),
            });
            const referralCode = generateReferralCode();
            const displayNameParts = (
              user.displayName || "DefaultDisplayName"
            ).split(" ");
            //make user dictionary
            const newUser = {
              userName: user.displayName || "DefaultDisplayName",
              email: user.email,
              firstName: displayNameParts[0] || null,
              lastName: displayNameParts.slice(1).join(" ") || null,
              birthday: user.birthday || null,
              phoneNumber: user.phoneNumber || null,
              gender: user.gender || null,
              photo: user.photoURL || null,
              overview: user.overview || null,
              products: user.products || null,
              timestamp: serverTimestamp(),
              location: null,
              userTitle: null,
              terms: false, //adding this to users object in firestore
              heardAboutUs: "",
              referralCode: referralCode,
              isVerified: true,
            };
            handleInvite(inviteCode, user.uid, user.email, pointsTable);
            //save the state of user
            await setUser(newUser);
            //set document in firestore here
            await setDoc(userRef, newUser);
            //user has no gender or birthday
            try {
              toast.success("Sign in with google successfull!", toastOptions);
              //redirect to more questions
              redirectUser("/SignInInput");
            } catch (error) {
              console.log("Error Signing in");
              toast.error("Sign up failed. Please try again.", toastOptions);
              redirect("/SignIn");
            }
          } else {
            console.log("GOING BACK TO OLD URL BYEEE!!");
            if (user.gender === null || user.birthday === null)
              redirectUser("/SignInInput");
            redirectUser(url);
          }
        }
      }
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      if (error.code === "auth/popup-closed-by-user") {
        // alert("Google Sign-In popup closed. Please try again.");
        redirectUser("/SignIn");
      } else if (error.code === "auth/cancelled-popup-request") {
        console.error(error);
        // alert("Google Sign-In request cancelled. Please try again.");
      } else {
        console.error(error);
        // alert("Google Sign-In failed. Please try again.");
      }
    }
  };
  const handleFacebookSignIn = async () => {
    try {
      const result = await facebookSignIn();
      console.log(result);
      // Check if the result is defined and has a 'user' property
      if (result && result.user) {
        // Extract user information from the Google sign-in result
        const { user } = result;
        if (user) {
          console.log("user facebook", user);
          createId(user.uid);
          if (!user.displayName) {
            const displayName = "DefaultDisplayName";
            await updateProfile(user, { displayName });
          }

          // Store user information in Firestore
          const db = getFirestore();
          const userRef = doc(db, "users", user.uid);

          // Check if the user already exists in Firestore
          const docSnap = await getDoc(userRef);
          console.log("here ser facebook", user);
          //new user
          if (!docSnap.exists()) {
            await WelcomeUser(user.displayName, user.providerData[0]?.email);
            fetch(
              "https://us-central1-duplookflock-beada.cloudfunctions.net/api/api/updateEmailVerified",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ uid: user.uid }),
              }
            );
            auth.currentUser.emailVerified = true;
            const referralCode = generateReferralCode();
            const displayNameParts = (
              user.displayName || "DefaultDisplayName"
            ).split(" ");
            //make user dictionary
            const newUser = {
              userName: user.displayName || "DefaultDisplayName",
              email: user.email,
              firstName: displayNameParts[0] || null,
              lastName: displayNameParts.slice(1).join(" ") || null,
              birthday: user.birthday || null,
              phoneNumber: user.phoneNumber || null,
              gender: user.gender || null,
              photo: user.photoURL || null,
              overview: user.overview || null,
              products: user.products || null,
              timestamp: serverTimestamp(),
              location: null,
              userTitle: null,
              terms: false, //adding this to users object in firestore
              heardAboutUs: "",
              referralCode: referralCode,
              isVerified: true,
            };
            handleInvite(inviteCode, user.uid, user.email, pointsTable);
            //user has no gender or birthday
            //save the state of user
            await setUser(newUser);
            //set document in firestore here
            await setDoc(userRef, newUser);
            //redirect to gender/birthday compoennt
            try {
              toast.success("Sign in with Facebook successfull!", toastOptions);
              //redirect to more questions
              redirectUser("/SignInInput");
            } catch (error) {
              console.log("Error Signing in");
              toast.error("Sign up failed. Please try again.", toastOptions);
              redirect("/SignIn");
            }
          } else {
            redirectUser(url);
          }
        }
      }
    } catch (error) {
      console.error("Error during Facebook sign-in:", error);
      if (error.code === "auth/popup-closed-by-user") {
        // alert("Facebook Sign-In popup closed. Please try again.");
        redirectUser("/login");
      } else if (error.code === "auth/cancelled-popup-request") {
        console.error(error);
        // alert("Facebook Sign-In request cancelled. Please try again.");
      } else {
        console.error(error);
        // alert("Facebook Sign-In failed. Please try again.");
      }
    }
  };
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center vh-100 py-2 ">
        <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
          <div className="card card-body d-flex flex-column align-items-center rounded-3 p-4 verifiyEmail pb-0 pt-2">
            <div className="">
              <Link className="text-body  fs-icon " to="/">
                <div
                  style={{
                    fontFamily: "Nunito Sans",
                  }}
                >
                  <i>lookflock&nbsp;</i>
                </div>
              </Link>
            </div>
            <p className="mt-1 fs-4 text-body">Verify your email address</p>
            <hr className="mt-0" />
            <p className="fs-5 text-center">{message}</p>
            <div className="px-4">
              <div
                className="btn btn-primary mb-2 w-100"
                onClick={resendEmailVerification}
              >
                Resend Email Verification
              </div>
              <Accordion flush>
                <style jsx>
                  {`
                    .verifiyEmail .accordion-button::after {
                      display: none !important; /* Hides the default arrow */
                    }
                  `}
                </style>

                <Accordion.Item
                  className="bg-transparent border-0"
                  eventKey={0}
                >
                  <Accordion.Header>
                    {/* <div className="btn btn-primary">Change Email Address</div> */}
                    <div className="btn btn-primary w-100">
                      Change Email Address
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="p-0 pt-3">
                    <form
                      className="d-flex flex-column gap-2 justify-content-center "
                      onSubmit={(e) => handleSubmitForm(e)}
                    >
                      <div>
                        <input
                          style={{ height: "2.5rem" }}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setErrorEmail("");
                            setErrorPass("");
                            setErrorConfirmPass("");
                          }}
                          className="w-100 rounded-2 p-2 border border-2"
                          type="email"
                          placeholder="New Email Address"
                          required
                        />
                        <div
                          className="text-start ps-2"
                          style={{
                            color: "red",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          {errorEmail}
                        </div>
                      </div>
                      <div>
                        <div className="d-flex">
                          <input
                            style={{ height: "2.5rem" }}
                            value={pass}
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => {
                              setPass(e.target.value);
                              setErrorEmail("");
                              setErrorPass("");
                              setErrorConfirmPass("");
                            }}
                            className="w-100 me-1 rounded-2 p-2 border border-2"
                            placeholder="New Password"
                            required
                          />
                          <div
                            className="input-group-text p-0"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <i
                              className={`fakepasswordicon fa-solid fa-eye${
                                showPassword ? "-slash" : ""
                              } cursor-pointer p-2 w-40px`}
                            ></i>
                          </div>
                        </div>
                        <div
                          className="text-start ps-2"
                          style={{
                            color: "red",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          {errorPass}
                        </div>
                      </div>
                      <div>
                        <div className="d-flex">
                          <input
                            style={{ height: "2.5rem" }}
                            value={confirmPass}
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => {
                              setConfirmPass(e.target.value);
                              setErrorEmail("");
                              setErrorPass("");
                              setErrorConfirmPass("");
                            }}
                            className="w-100 me-1 rounded-2 p-2 border border-2"
                            placeholder="Confirm Password"
                            required
                          />
                          <div
                            className="input-group-text p-0"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            <i
                              className={`fakepasswordicon fa-solid fa-eye${
                                showPassword ? "-slash" : ""
                              } cursor-pointer p-2 w-40px`}
                            ></i>
                          </div>
                        </div>
                        <div
                          className="text-start ps-2"
                          style={{
                            color: "red",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          {errorConfirmPass}
                        </div>
                      </div>
                      <button
                        className="btn btn-primary w-100 m-auto"
                        type="submit"
                      >
                        Submit
                      </button>
                    </form>
                    <div className="container d-flex flex-column justify-content-center align-items-center mt-1 p-0">
                      <div className="w-100 d-flex align-items-center">
                        <hr className="flex-grow-1" />
                        <span className="px-2"> Or continue with </span>
                        <hr className="flex-grow-1" />
                      </div>

                      <div className="d-flex justify-content-center mt-1 mb-0">
                        <button
                          className="btn mx-4"
                          onClick={handleGoogleSignIn}
                        >
                          <img
                            src={googleLogoPath}
                            className="mx-1"
                            alt="Google"
                            style={{
                              width: "40px",
                              height: "40px",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </button>
                        <button
                          className="btn mx-4"
                          onClick={handleFacebookSignIn}
                        >
                          <img
                            src={facebookLogoPath}
                            className="mx-1"
                            alt="Facebook"
                            style={{
                              width: "40px",
                              height: "40px",
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <TermsAndConditions />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShareMenu2 from "../components/ShareMenu2";
import AddToList from "../components/AddToList";
import AddOrRemoveLike from "./AddOrRemoveLike";
import { Card, Spinner } from "react-bootstrap";
import "./style.css";
import { UserAuth } from "../Context";
import AddOrRemoveFavourites from "./AddOrRemoveFavourites";
import BrandDisplay from "../NewsFeed/BrandDisplay";
import formatPrice from "../lib/formatPrice";
import { getBrandDetails } from "../lib/getBrandDetails";
import useFetch from "../hooks/useFetch";
import usePost from "../hooks/usePost";
import { CheckCircleFill, PlusCircleFill } from "react-bootstrap-icons";
import { db } from "../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useSocketStore from "../StatesStore/Socket";

const ProductCard3 = ({ product, isStrip, addButtonData }) => {
  const [likeCount, setLikeCount] = useState(product?.likes);
  const [favouritesCount, setFavouritesCount] = useState(product?.favourites);
  const [brandData, setBrandData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInList, setIsInList] = useState(false);
  const [showToolTipLike, setShowToolTipLike] = useState(true);
  const [showToolTipFavorites, setShowToolTipFavorites] = useState(true);
  const [showToolTipShare, setShowToolTipShare] = useState(true);
  const [showToolTipAddToList, setShowToolTipAddToList] = useState(true);
  const [loginCount, setLoginCount] = useState(0);
  // const socket = useSocketStore((state) => state.socket);
  const [productDetails, setProductDetails] = useState(product);

  const handleDismiss = () => {
    setShowToolTipLike(false);
    setShowToolTipFavorites(false);
    setShowToolTipShare(false);
    setShowToolTipAddToList(false);
  };

  // const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  const { user } = UserAuth();
  const { data: activeBrands } = useFetch(
    `${process.env.REACT_APP_API}/api/activeBrands`
  );
  let url = process.env.REACT_APP_API + `/api/activity/${user?.uid}`;

  // let url = process.env.REACT_APP_API+`/api/logs/${user?.uid}`;
  const { data: postData, post } = usePost(url);
  // useEffect(() => {
  //   // When the component mounts, subscribe to product updates
  //   socket.emit("subscribeToProduct", product?.id);

  //   // Listen for updates from the server
  //   socket.on("productUpdate", (data) => {
  //     if (data.productId === product?.id) {
  //       console.log("productUpdate", data.newDetails);

  //       setProductDetails(data.newDetails);
  //     }
  //   });
  // }, [product?.id]);

  // console.log("productDetails from productcard3", productDetails);

  const fetchBrandData = async () => {
    try {
      // Fetch the brand's display name and logo using the getDisplayName function
      const result = await getBrandDetails(product?.supplier, activeBrands);
      setBrandData(result);
    } catch (error) {
      console.error("Error getting brand data:", error);
    }
  };

  const checkProductInList = async () => {
    const userRef = doc(
      db,
      "users",
      addButtonData.userId,
      "shoppingList",
      addButtonData.selectedShopList,
      "products",
      product?.id
    );
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      setIsInList(true);
    }
  };
  const fetchLoginCount = async () => {
    const userRef = doc(db, "users", user?.uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      setLoginCount(userSnap?.data().loginCount || 0);
    }
  };
  useEffect(() => {
    if (!product || !activeBrands) return;
    fetchBrandData();
  }, [product, activeBrands]);

  useEffect(() => {
    if (!user) return;
    fetchLoginCount();
  }, [user]);

  useEffect(() => {
    if (!product || !addButtonData) return;
    checkProductInList();
  }, [product, addButtonData]);

  const handleImageLoad = () => {
    setLoading(false);
  };
  const renderTooltipLike = (props) => (
    <Tooltip
      id="button-tooltip"
      className="custom-tooltip bg-secondary rounded-2 p-0"
      {...props}
    >
      👋 Welcome! Click here to like this item.
    </Tooltip>
  );
  const renderTooltipFavorites = (props) => (
    <Tooltip
      id="button-tooltip"
      className="custom-tooltip bg-secondary rounded-2 p-0"
      {...props}
    >
      👋 Welcome! Click here to add this item to your Favorites list.
    </Tooltip>
  );
  const renderTooltipShare = (props) => (
    <Tooltip
      id="button-tooltip"
      className="custom-tooltip bg-secondary rounded-2 p-0"
      {...props}
    >
      👋 Welcome! Click here to share this item with your loved ones.
    </Tooltip>
  );
  const renderTooltipAddToList = (props) => (
    <Tooltip
      id="button-tooltip"
      className="custom-tooltip bg-secondary rounded-2 p-0"
      {...props}
    >
      👋 Welcome! Click here to save this item to your list.
    </Tooltip>
  );

  return (
    product?.imageUrl && (
      <div className="m-1 m-sm-1">
        <style jsx>{`
          .custom-tooltip {
            --bs-tooltip-bg: var(--bs-violet-bg);
            --bs-tooltip-color: var(--bs-white);
            color: white;
            max-width: 300px;
            text-align: center;
            font-size: 13px;
          }

          .custom-tooltip .btn {
            margin-top: 10px;
          }
        `}</style>
        <Card>
          <div style={{ position: "relative", height: "auto" }}>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#eef0f2", // background for the placeholder
                }}
              >
                {/* Placeholder: Can be an image, a spinner, or any other component */}
                <Spinner
                  animation="border"
                  style={{ color: "grey", width: "20px", height: "20px" }}
                />
              </div>
            )}
            <Link to={`/ProductDetails/${product?.id}`}>
              <Card.Img
                src={product?.imageUrl}
                style={{
                  height: `28vh`,
                  objectFit: "cover",
                  opacity: loading ? 0 : 1, // Hide the image until it's fully loaded
                  transition: "opacity 0.5s ease-in-out",
                }}
                onLoad={handleImageLoad}
                loading="lazy"
              />
            </Link>
          </div>

          {/* Price Tag */}
          <div
            className="position-absolute rounded-pill top-0 start-0 m-2"
            style={{
              fontSize: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
          >
            {productDetails?.newPrice && (
              <p className="m-0 text-white px-2 py-1">
                PKR{" "}
                {productDetails?.newPrice
                  ? formatPrice(productDetails.newPrice)
                  : formatPrice(productDetails.oldPrice)}
              </p>
            )}
          </div>

          {/* Plus Button to add product in the list */}
          {addButtonData && (
            <div
              className="position-absolute rounded-circle top-0 end-0 m-2"
              style={{
                fontSize: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (!isInList) {
                  addButtonData.handleAddProductToList(product?.id, product);
                  setIsInList(true);
                }
              }}
            >
              {isInList ? (
                <CheckCircleFill className="fs-4 text-body" />
              ) : (
                <PlusCircleFill className="fs-4 text-body" />
              )}
            </div>
          )}
          {/* Strip */}
          {loading && (isStrip === undefined || isStrip === true) && (
            <div
              className="d-inline-flex flex-column align-items-center justify-content-center rounded-pill py-2 text-light position-absolute bottom-0 end-0 m-2"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <OverlayTrigger
                show={loginCount > 5 ? false : showToolTipLike}
                placement="left" // You can change this to 'top', 'left','right, 'bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipLike}
              >
                <Link
                  className="text-center text-light"
                  onClick={handleDismiss}
                  onMouseLeave={handleDismiss}
                  onMouseEnter={() => setShowToolTipLike(true)}
                >
                  <AddOrRemoveLike
                    user={user}
                    product={product}
                    setLikeCount={setLikeCount}
                    componentName={"strip2"}
                  />
                  <div style={{ fontSize: "10px", marginTop: "-4px" }}>
                    {likeCount ?? 0}
                  </div>
                </Link>
              </OverlayTrigger>
              <Link className="text-center d-flex flex-column nav-link" to="">
                <i className="bi bi-eye"></i>
                <div
                  style={{
                    fontSize: "10px",
                    marginTop: "-5px",
                    marginBottom: "-2px",
                  }}
                >
                  {productDetails?.views ?? 0}
                </div>
              </Link>
              <OverlayTrigger
                show={loginCount > 5 ? false : showToolTipFavorites}
                placement="left" // You can change this to 'top', 'left','right, 'bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipFavorites}
              >
                <Link
                  className="text-center text-light"
                  onClick={handleDismiss}
                  onMouseLeave={handleDismiss}
                  onMouseEnter={() => setShowToolTipFavorites(true)}
                >
                  <AddOrRemoveFavourites
                    product={product}
                    user={user}
                    setFavouritesCount={setFavouritesCount}
                  />
                  <div style={{ fontSize: "10px", marginTop: "-7px" }}>
                    {favouritesCount ?? 0}
                  </div>
                </Link>
              </OverlayTrigger>
              <OverlayTrigger
                show={loginCount > 5 ? false : showToolTipShare}
                placement="left" // You can change this to 'top', 'left','right, 'bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipShare}
              >
                <span
                  onClick={handleDismiss}
                  onMouseLeave={handleDismiss}
                  onMouseEnter={() => setShowToolTipShare(true)}
                >
                  <ShareMenu2 product={product} />
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                show={loginCount > 5 ? false : showToolTipAddToList}
                placement="left" // You can change this to 'top', 'left','right, 'bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltipAddToList}
              >
                <span
                  onClick={handleDismiss}
                  onMouseLeave={handleDismiss}
                  onMouseEnter={() => setShowToolTipAddToList(true)}
                >
                  <AddToList
                    product={product}
                    componentName={"strip2"}
                    setFavouritesCount={setFavouritesCount}
                  />
                </span>
              </OverlayTrigger>
            </div>
          )}
        </Card>

        <div className="pe-1 pb-1">
          <div className="d-flex align-items-center">
            {/* Logo */}
            <div
              className="avatar me-2"
              style={{ width: "35px", height: "35px" }}
            >
              <Link to={"/BrandHome/" + product?.supplier}>
                <img
                  className="avatar-img rounded"
                  src={brandData?.logo}
                  alt=""
                />
              </Link>
            </div>
            <div
              className="d-flex flex-column justify-content-start"
              style={{ width: "78%" }}
            >
              <div className="mb-0">
                <BrandDisplay
                  brandData={brandData}
                  post={product}
                  type={"ProductCard"}
                />
              </div>
              <div
                className="fw-bold h6 position-relative"
                style={{
                  marginTop: "2px",
                  fontSize: "12px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {product?.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ProductCard3;

import React from 'react'
import { MessageInput, useChannelStateContext } from 'stream-chat-react';
import { UserMessage } from '../EmailSystem/sendEmails';
import { UserAuth } from '../Context';

function CustomMessageInput(props) {
    const { channel } = useChannelStateContext();
    const { user } = UserAuth();

    const customOverrideSubmitHandler = async (message, channelCid) => {
  
      channel.sendMessage(message);
      const otherMembers = Object.values(channel.state.members)
      .filter(member => member.user.id !== user.uid)
      .map(member => member.user);

      const offlineUsers = otherMembers.filter(member => !member.online);
      if (offlineUsers.length > 0) {
        const emailPromises = offlineUsers.map(receiver =>
          UserMessage(
            user.displayName || user.email, // Sender name
            receiver.name || receiver.id,     // Receiver name
            receiver.email                    // Receiver email
          )
            .then(() => {
              console.log(`Email sent successfully to ${receiver.email}`);
            })
            .catch((error) => {
              if (error.code === 'ECONNABORTED') {
                console.warn(
                  `Email sending timed out for ${receiver.email}. Consider increasing the timeout setting.`
                );
              } else {
                console.error('Email error:', error);
              }
            })
        );
  
        await Promise.allSettled(emailPromises);
      }
    
    };
  
    return (
      <MessageInput overrideSubmitHandler={customOverrideSubmitHandler} {...props} />
    );
}

export default CustomMessageInput
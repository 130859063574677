import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { toastOptions } from "./lib/toastOptions";
import { db } from "./FirebaseConfig";
import { collection, getDocs, limit, query } from "firebase/firestore";
import { Link } from "react-router-dom";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import LoadingSpinner from "./UserProfile/LoadingSpinner";

function Category({ gender, fullScreen }) {
  const [loading, setLoading] = useState(true);
  const [categoryImages, setCategoryImages] = useState({});
  const [primaryCategories, setPrimaryCategories] = useState({});
  const storage = getStorage();
  const fetchCategories = async () => {
    try {
      const categoriesCol = collection(db, "universalCategories");
      const categoriesSnapshot = await getDocs(categoriesCol);
      let genderArray = [];
      categoriesSnapshot.docs.forEach((doc) => {
        let GenderData = doc.data();
        genderArray.push(GenderData);
      });
      // console.log("GenderData", genderArray);
      let maleCategories = genderArray[0].subCategories;
      // console.log("Male", maleCategories);
      let femaleCategories = genderArray[1].subCategories;
      // console.log("Female", femaleCategories);
      if (gender === "male") {
        setPrimaryCategories(maleCategories);
        // setSecondaryCategories(femaleCategories);
      } else if (gender === "female") {
        setPrimaryCategories(femaleCategories);
      }
    } catch (error) {
      console.error("Error fetching brand display names:", error);
      toast.error("Error fetching brand display names:", toastOptions);
    }
  };
  const fetchImages = async (gender) => {
    try {
      const imagesRef = ref(
        storage,
        `categoryPage/${gender === "male" ? "Men" : "Women"}`
      );
      const imageList = await listAll(imagesRef);

      // Create an object to store images by category
      const imagesByCategory = {
        "Ready to Wear": [],
        Unstitched: [],
        "Western Wear": [],
      };

      // Get all URLs and filenames
      const urlsAndNames = await Promise.all(
        imageList.items.map(async (item) => ({
          url: await getDownloadURL(item),
          name: item.name,
        }))
      );

      // Sort images into categories
      urlsAndNames.forEach(({ url, name }) => {
        if (name.includes("ReadyToWear")) {
          // if (!imagesByCategory["Ready to Wear"])
          //   imagesByCategory["Ready to Wear"] = [];
          imagesByCategory["Ready to Wear"].push(url);
        }
        if (name.includes("Unstitched")) {
          // if (!imagesByCategory["Unstitched"])
          //   imagesByCategory["Unstitched"] = [];
          imagesByCategory["Unstitched"].push(url);
        }
        if (name.includes("WesternWear")) {
          // if (!imagesByCategory["Western Wear"])
          //   imagesByCategory["Western Wear"] = [];
          imagesByCategory["Western Wear"].push(url);
        }
      });

      setCategoryImages(imagesByCategory);
    } catch (error) {
      console.error("Error fetching images:", error);
      toast.error("Error fetching category images", toastOptions);
    }
  };
  const fetchData = async () => {
    setLoading(true);
    await fetchCategories();
    await fetchImages(gender);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [gender]);
  if (loading) {
    return (
      <div className="card w-100">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="d-flex flex-column w-100 card p-3">
      {Object.keys(primaryCategories)
        .sort()
        .map((categoryKey) => (
          <div
            key={categoryKey}
            className={`mt-1 d-inline-flex flex-column align-items-start col-lg-${
              fullScreen ? "12" : "8"
            }`}
          >
            <div className="d-flex flex-row justify-content-between w-100">
              <li className="h5 fw-light ms-1" style={{ listStyle: "none" }}>
                {categoryKey}
              </li>
              <Link
                to={`/AllProducts/${
                  gender === "male" ? "Men" : "Women"
                }/${categoryKey}/All`}
                className="nav-link text-primary"
              >
                <u>View All Products</u>
              </Link>
            </div>
            <ul
              className={`list-inline d-flex flex-row gap-3 gap-sm-4 gap-md-5 mt-2 mb-1 hiddenScrollbar w-100`}
              style={{ overflow: "auto", whiteSpace: "nowrap" }}
            >
              {primaryCategories[categoryKey].map((item, index) => {
                if (item.displayName !== "All")
                  return (
                    <li key={index}>
                      <Link
                        to={`/AllProducts/${
                          gender === "male" ? "Men" : "Women"
                        }/${categoryKey}/${item.name}`}
                        className="d-flex flex-column align-items-center text-center"
                      >
                        <div className="position-relative">
                          <div
                            style={{
                              zIndex: "0",
                            }}
                            className="bg-body position-absolute bottom-0 start-0 h-75 w-100 rounded-2"
                          ></div>
                          <img
                            style={{
                              zIndex: "20",
                              height: "8rem",
                            }}
                            width={"80rem"}
                            className="rounded-2 position-relative"
                            src={categoryImages[categoryKey]?.[index - 1] || ""}
                            alt=""
                            loading="lazy"
                          />
                        </div>
                        <div
                          className="nav-item text-wrap mt-2 h6 fw-light"
                          style={{ width: "5.5rem", wordWrap: "break-word" }}
                        >
                          {item.displayName}
                        </div>
                      </Link>
                    </li>
                  );
              })}
            </ul>
          </div>
        ))}
    </div>
  );
}

export default Category;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useModalStore from "../StatesStore/ModalStore";
import { dataLike, titleLike } from "../Content/ModalContent";
import handleLikeClick from "../hooks/product/likeProduct";
import useLikeStore from "../StatesStore/LikeStore";
import usePoints from "../hooks/user/usePoints";
import usePointsStore from "../StatesStore/PointsTable";
import useLogActivity from "../hooks/user/useLogActivity";

{
  /* Description: Handles the functionality to like or unlike a product, managing like counts and UI state related to liking a product. */
}

function AddOrRemoveLike({ user, product, setLikeCount, componentName }) {
  const likedProducts = useLikeStore((state) => state.likedProducts);
  const addLike = useLikeStore((state) => state.addLike);
  const removeLike = useLikeStore((state) => state.removeLike);
  const { openModal } = useModalStore();
  const [isLiked, setIsLiked] = useState(false);
  const { addPoints, removePoints } = usePoints();
  const pointsTable = usePointsStore((state) => state.pointsTable);
  const { logActivity, error } = useLogActivity(user?.uid);

  const handleClick = () => {
    if (!user) {
      // Check if user is not logged in before opening modal
      openModal("SignInModal", titleLike, dataLike); // Open modal here
    }
  };
  const handleUpdateLike = async () => {
    if (likedProducts.includes(product?.id)) {
      removeLike(product?.id);

      //deleting points
      const postingPoints = pointsTable.likeAProduct || 0;
      removePoints({
        userId: user?.uid,
        type: "likeAProduct",
        pointsRemoved: postingPoints,
        fields: {
          productId: product?.id,
        },
      });

      const activityData = {
        ...product,
        productId: product.id,
        type: "productDislike",
      };

      logActivity(activityData);
    } else {
      addLike(product?.id);

      //adding points
      const postingPoints = pointsTable.likeAProduct || 0;

      addPoints({
        userId: user?.uid,
        type: "likeAProduct",
        pointsAwarded: postingPoints,
        fields: {
          productId: product?.id,
        },
      });

      const activityData = {
        ...product,
        productId: product.id,
        type: "productLike",
      };

      logActivity(activityData);
    }
  };
  // const fetchLikeData = async () => {
  //   if (!user || !product) return;
  //   // const likeDocRef = doc(db, `users/${user?.uid}/likeList`, product?.id);
  //   const likeDocRef = doc(db, "users", user?.uid, "likeList", product?.id);
  //   const likeDocSnapshot = await getDoc(likeDocRef);
  //   setIsLiked(likeDocSnapshot?.exists());
  // };

  // useEffect(() => {
  //   fetchLikeData();
  // }, [user, product?.id]);
  useEffect(() => {
    setIsLiked(likedProducts.includes(product?.id));
  }, [likedProducts]);
  return (
    <Link
      className={`nav-link ${
        componentName == "strip" || componentName == "strip2" ? "" : ""
      } gap-2 p-0 nav-item `}
      onClick={async () => {
        if (!user) {
          handleClick();
        } else if (!user?.emailVerified) {
          openModal("VerifyEmailModal", "", {
            userName: user?.displayName,
            email: user?.email,
          });
        } else {
          setIsLiked(!isLiked);
          setLikeCount((prevLikeCount) => {
            const newLikeCount = isLiked
              ? Math.max(prevLikeCount - 1, 0)
              : prevLikeCount + 1;
            return newLikeCount;
          });
          await handleLikeClick(user?.uid, product);
          await handleUpdateLike();
        }
      }}
    >
      {!user && (
        <Link
          className={`text-${
            componentName === "strip2" ||
            componentName === "productDescriptions"
              ? "light"
              : ""
          } p-0`}
        >
          <i className={"bi bi-hand-thumbs-up fs-6"}></i>
        </Link>
      )}
      {user && (
        <i
          className={`bi ${
            isLiked ? "bi-hand-thumbs-up-fill fs-6" : "bi-hand-thumbs-up fs-6"
          }`}
        ></i>
      )}
      {/* {componentName === "strip" || componentName === "strip2" ? "" : "Like"} */}
    </Link>
  );
}

export default AddOrRemoveLike;

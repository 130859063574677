import React, { useEffect, useRef, useState } from "react";
import { UserAuth } from "../Context";
import { Link, useLocation } from "react-router-dom";
import {
  onSnapshot,
  doc,
  collection,
  query,
  where,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProfilePicture from "../UserProfile/ProfilePicture";
import ShareMenu from "../Referral/ShareMenu";
import Notification from "../Notifications/Notification";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import { Dropdown, FormControl, Button } from "react-bootstrap";
import HeaderChatNotification from "../Chat/HeaderChatNotification";
import { Gear } from "react-bootstrap-icons";
import useChatClient from "../StatesStore/ChatClient";
import useModalStore from "../StatesStore/ModalStore";
import useSearchInputStore from "../StatesStore/SearchInputStore";
import useSearchBarOpenState from "../StatesStore/SearchBarOpen";

function UserMenuHeader3() {
  const { user, logOut } = UserAuth();
  const [theme, setTheme] = useState("");
  const { openModal } = useModalStore();
  const { isSearchBarOpen, setIsSearchBarOpen } = useSearchBarOpenState();
  const location = useLocation();
  const [totalShoppingListCount, setTotalShoppingList] = useState(0);
  const [cartCount, setCartCount] = useState(0);

  const { userData } = useFetchUserData(user?.uid);
  const dropdownRef = useRef(null);
  const [lastSeenNotification, setLastSeenNotification] = useState(
    userData?.lastSeenNotification || null
  );
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  // console.log("lastSeenNotification from userMenuHeader3", lastSeenNotification);
  const [lastSeenMsgs, setLastSeenMsgs] = useState(
    userData?.lastSeenMsgs || null
  );
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const client = useChatClient((state) => state.client);
  const {
    searchInput,
    setSearchInput,
    resetSearchInput,
  } = useSearchInputStore();
  useEffect(() => {
    setTheme(localStorage.getItem("theme"));
  }, [theme]);

  const signOutUser = async () => {
    logOut();
  };
  useEffect(() => {
    if (!user) {
      setTotalShoppingList(0);
    }
  }, [user]);
  useEffect(() => {
    if (!client || !user) return;

    // Check if the client is connected by verifying that a user exists on the client.
    if (!client.user) {
      console.warn(
        "StreamChat client is not connected. Skipping event subscription."
      );
      return;
    }

    const handleEvent = (event) => {
      if (event.total_unread_count !== undefined) {
        setNewMessagesCount(event.total_unread_count);
        console.log(`Unread messages count: ${event.total_unread_count}`);
      }
    };

    client.on(handleEvent);
    // Initial fetch for unread count
    client?.getUnreadCount(user?.uid).then((result) => {
      setNewMessagesCount(result?.total_unread_count || 0);
    });

    return () => {
      if (client) {
        client.off(handleEvent);
      }
    };
  }, [client, user]);
  useEffect(() => {
    if (!user) return;

    // User document listener
    const userDocRef = doc(db, "users", user.uid);
    const unsubscribeUserDoc = onSnapshot(userDocRef, (docSnapshot) => {
      const data = docSnapshot.data();

      // Update lastSeenNotification and lastSeenMsgs
      const updatedLastSeenNotification = data?.lastSeenNotification;
      const updatedLastSeenMsgs = data?.lastSeenMsgs || new Date();

      setLastSeenNotification(updatedLastSeenNotification);
      setLastSeenMsgs(updatedLastSeenMsgs);

      // Notifications query
      const notificationsRef = collection(
        db,
        "users",
        user.uid,
        "notifications"
      );
      const notificationsQuery = query(
        notificationsRef,
        where("timestamp", ">", updatedLastSeenNotification)
      );

      const unsubscribeNotifications = onSnapshot(
        notificationsQuery,
        (snapshot) => {
          setNewNotificationsCount(snapshot.size);
        }
      );

      // Cleanup function for sub-collections
      return () => {
        unsubscribeNotifications();
      };
    });

    // Cleanup for user document listener
    return () => {
      unsubscribeUserDoc();
    };
  }, [user]);

  useEffect(() => {
    const fetchShoppingListCount = async () => {
      if (!user) return;
      const userRef = doc(db, "users", user.uid);
      try {
        const unsubscribeShoppingList = onSnapshot(userRef, (doc) => {
          const userData = doc.data();
          const shoppingListCount = userData?.shoppingListCount || 0;
          setTotalShoppingList(shoppingListCount);
        });

        return () => unsubscribeShoppingList(); // Unsubscribe when the component unmounts
      } catch (error) {
        console.error("Error fetching shopping list count:", error);
      }
    };

    fetchShoppingListCount();
  }, [user]);

  useEffect(() => {
    if (!user) {
      setCartCount(0);
      return;
    }

    const cartRef = collection(db, "users", user.uid, "cart");
    const unsubscribeCart = onSnapshot(cartRef, (snapshot) => {
      setCartCount(snapshot.size);
    });

    return () => unsubscribeCart();
  }, [user]);

  const handleTheme = (mode) => {
    setTheme(mode);
    document.documentElement.setAttribute("data-bs-theme", mode);
  };
  const handleMenuItemClick = () => {
    if (dropdownRef.current) {
      dropdownRef.current.querySelector(".dropdown-toggle").click();
    }
  };
  const handleOutsideClick = () => {
    const brand = document.getElementById("brandsCollapse");
    // const category = document.getElementById("categoryCollapse");
    const menCategory = document.getElementById("menCollapse");
    const womenCategory = document.getElementById("womenCollapse");
    if (brand.classList.contains("show")) {
      brand.classList.remove("show");
    }
    // if (category.classList.contains("show")) {
    //   category.classList.remove("show");
    // }
    if (user && menCategory.classList.contains("show")) {
      menCategory.classList.remove("show");
    }
    if (user && womenCategory.classList.contains("show")) {
      womenCategory.classList.remove("show");
    }
  };
  if (!userData) return null;
  const updateTimestamps = async () => {
    if (!user) return;
    const userDocRef = doc(db, "users", user.uid);
    await updateDoc(userDocRef, {
      lastSeenNotification: serverTimestamp(),
      lastSeenMsgs: serverTimestamp(),
    });

    setLastSeenNotification(new Date());
    setLastSeenMsgs(new Date());
    setNewMessagesCount(0);
    setNewNotificationsCount(0);
  };

  const handleDropdownToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);

    // Update timestamps when dropdown is opened
    if (isOpen && (newNotificationsCount > 0 || newMessagesCount > 0)) {
      setTimeout(() => {
        updateTimestamps();
      }, 7000);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchInput.length > 2) {
      setIsSearchBarOpen(false);
      window.location.href = `#/search/${searchInput}`;
    }
  };
  return (
    <>
      {isSearchBarOpen ? (
        <li className="nav-item mx-0 mx-md-2 d-flex align-items-center search-container">
          <form
            className={`rounded position-relative ${
              isSearchBarOpen ? "show" : ""
            }`}
            id="search-form"
            style={{
              width: "15.5rem",
            }}
          >
            <input
              className="form-control ps-5 bg-light py-1"
              type="search"
              name="input"
              placeholder="Search..."
              aria-label="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="btn bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y"
              type="submit"
              onClick={(e) => {
                setIsSearchBarOpen(false);
                setSearchInput("");
                handleSearch(e);
              }}
            >
              <i className="bi bi-search fs-5"></i>
            </button>
          </form>
        </li>
      ) : (
        <>
          <li className="nav-item mx-0 mx-md-2 d-flex align-items-center search-container d-md-none">
            <div
              className="search-wrapper d-flex align-items-center"
              style={{ width: "100%" }}
            >
              <div
                className="nav-link p-0 search-icon"
                onClick={() => {
                  setIsSearchBarOpen(true);
                }}
              >
                <i className="bi bi-search fs-4"></i>
              </div>
            </div>
          </li>
          <li
            className="nav-item mx-0 mx-md-2 collapsible-nav-item"
            onClick={() => {
              handleOutsideClick();
            }}
          >
            <Link
              onClick={() => {
                if (!user?.emailVerified) {
                  openModal("VerifyEmailModal", "", {
                    userName: user?.displayName,
                    email: user?.email,
                  });
                }
              }}
              to={user?.emailVerified && "/chat"}
              className="nav-link p-0 position-relative "
            >
              {newMessagesCount > 0 && (
                <span
                  className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle ${
                    newMessagesCount > 9 ? "px-1" : ""
                  } bg-danger`}
                  style={{
                    top: "13%",
                    left: "99%",
                    fontSize: "10px",
                    minHeight: "7.5px",
                    minWidth: "7px",
                  }}
                >
                  {newMessagesCount}
                </span>
              )}
              <i
                className={`bi bi-chat-square-text${
                  location.pathname.includes("/chat") ? "-fill" : ""
                } fs-4`}
              ></i>
            </Link>
          </li>
          <li
            className="nav-item mx-0 mx-md-2 collapsible-nav-item"
            onClick={() => handleOutsideClick()}
          >
            <Link
              onClick={() => {
                if (!user?.emailVerified) {
                  openModal("VerifyEmailModal", "", {
                    userName: user?.displayName,
                    email: user?.email,
                  });
                }
              }}
              to={user?.emailVerified && "/shoppingLists"}
              className="nav-link p-0 shopping-list-link position-relative "
            >
              {totalShoppingListCount > 0 && (
                <span
                  className={`d-flex justify-content-center align-items-center position-absolute translate-middle rounded-circle badge bg-danger ${
                    totalShoppingListCount > 9 ? "px-1" : ""
                  }`}
                  style={{
                    top: "13%",
                    left: "99%",
                    fontSize: "10px",
                    minHeight: "7.5px",
                    minWidth: "7px",
                  }}
                >
                  {totalShoppingListCount}
                </span>
              )}
              <i
                className={`bi bi-bookmark${
                  location.pathname.includes("/shoppingLists") ? "-fill" : ""
                } fs-4`}
              ></i>
            </Link>
          </li>
          <li
            className="nav-item mx-0 mx-md-2 collapsible-nav-item"
            onClick={() => handleOutsideClick()}
          >
            <Link className="nav-link p-0 position-relative" to="/cart">
              {cartCount > 0 && (
                <span
                  className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle bg-danger ${
                    cartCount > 9 ? "px-1" : ""
                  }`}
                  style={{
                    top: "13%",
                    left: "99%",
                    fontSize: "10px",
                    minHeight: "7.5px",
                    minWidth: "7px",
                  }}
                >
                  {cartCount}
                </span>
              )}
              <i
                className={`bi bi-cart${
                  location.pathname.includes("/cart") ? "-fill" : ""
                } fs-4`}
              ></i>
            </Link>
          </li>
          <li className="nav-item dropdown userMenuHeader3" ref={dropdownRef}>
            <style jsx>
              {`
                .userMenuHeader3 .dropdown-toggle::after {
                  display: none;
                }
              `}
            </style>
            <Dropdown
              align="end"
              autoClose
              onToggle={() => {
                handleDropdownToggle();
                handleOutsideClick();
              }}
            >
              <Dropdown.Toggle
                variant="link"
                className="nav-link p-0 position-relative"
              >
                <i className={`bi bi-person fs-3`}></i>
                {newNotificationsCount > 0 && (
                  <span
                    className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle ${
                      newNotificationsCount > 9 ? "px-1" : ""
                    } bg-danger`}
                    style={{
                      top: "14%",
                      left: "90%",
                      fontSize: "10px",
                      minHeight: "7.5px",
                      minWidth: "7px",
                    }}
                  >
                    {newNotificationsCount}
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-animation pt-3 small me-md-n3"
                style={{ width: "350px" }}
              >
                <div className="px-1 " onClick={handleMenuItemClick}>
                  <Notification
                    user={user}
                    userData={userData}
                    newNotificationsCount={newNotificationsCount}
                  />
                </div>
                <Dropdown.Divider className="my-2" />
                <Dropdown.Item
                  as="div"
                  className="d-flex justify-content-center align-items-center p-0 w-100"
                >
                  <Dropdown.Item
                    as={Link}
                    to={`/userProfile/${user.uid}/shoppinglist`}
                    className="bg-primary-soft-hover ps-3 p-0 d-flex w-100"
                  >
                    <i className="bi bi-person fa-fw me-2"></i>
                    <div>Profile</div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="p-0 d-flex"
                    onClick={() => {
                      if (!user?.emailVerified) {
                        openModal("VerifyEmailModal", "", {
                          userName: user?.displayName,
                          email: user?.email,
                        });
                      }
                    }}
                  >
                    <ShareMenu />
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/SignIn"
                    className="bg-danger-soft-hover p-0 d-flex"
                    onClick={signOutUser}
                  >
                    <i className="bi bi-power fa-fw me-2"></i>
                    <div>Sign Out</div>
                  </Dropdown.Item>
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item
                  as="div"
                  className="modeswitch-item theme-icon-active d-flex justify-content-between gap-3 align-items-center pb-0 px-3"
                >
                  <Link
                    to={user?.emailVerified ? `/settings/accountSetting` : "#"}
                    onClick={() => {
                      if (!user?.emailVerified) {
                        openModal("VerifyEmailModal", "", {
                          userName: user?.displayName,
                          email: user?.email,
                        });
                      }
                    }}
                    className="d-flex gap-2 align-items-center nav-link p-0"
                  >
                    <Gear />
                    <div>Settings</div>
                  </Link>
                  <div className="vr border mx-1 nav-link p-0"></div>
                  <div
                    className="d-flex gap-2 align-items-center nav-link p-0"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="me-2">Mode:</div>
                    <div className="d-flex gap-2">
                      <button
                        aria-label="Light Mode"
                        type="button"
                        className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                          theme === "light" ? "active text-white" : ""
                        }`}
                        onClick={() => {
                          handleTheme("light");
                          localStorage.setItem("theme", "light");
                        }}
                      >
                        <i className="bi bi-sun fa-fw"></i>
                      </button>
                      <button
                        aria-label="Dark Mode"
                        type="button"
                        className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                          theme === "dark" ? "active text-white" : ""
                        }`}
                        onClick={() => {
                          handleTheme("dark");
                          localStorage.setItem("theme", "dark");
                        }}
                      >
                        <i className="bi bi-moon-stars fa-fw"></i>
                      </button>
                      <button
                        aria-label="Auto Mode"
                        type="button"
                        className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                          theme === "" || theme === "auto"
                            ? "active text-white"
                            : ""
                        }`}
                        onClick={() => {
                          handleTheme("auto");
                          localStorage.setItem("theme", "auto");
                        }}
                      >
                        <i className="bi bi-circle-half fa-fw"></i>
                      </button>
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </>
      )}
      {/* <style jsx>
        {`
          .searchBar {
            width: 2.5rem;
            transition: width 0.2s ease;
          }
          .searchBar.show {
            width: 15.5rem;
          }
        `}
      </style>
      <li className="nav-item mx-0 mx-md-2 d-flex align-items-center search-container d-md-none">
        <form
          className={`rounded position-relative searchBar ${
            isSearchBarOpen ? "show" : ""
          }`}
          onSubmit={handleSearch}
        >
          <FormControl
            className={`${
              isSearchBarOpen ? "ps-5 bg-light" : "px-0 border-0 mb-1"
            } `}
            type="search"
            name="searchInput"
            placeholder={isSearchBarOpen ? "Search..." : ""}
            aria-label="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <Button
            variant="mode"
            type="submit"
            className="bg-transparent py-0 position-absolute top-50 start-0 translate-middle-y"
            onClick={() => setIsSearchBarOpen(!isSearchBarOpen)}
          >
            <i className="bi bi-search fs-4"></i>
          </Button>
        </form>
      </li>
      {!isSearchBarOpen && (
        <>
          <li
            className="nav-item mx-0 mx-md-2 collapsible-nav-item"
            onClick={() => {
              handleOutsideClick();
            }}
          >
            <Link
              onClick={() => {
                if (!user?.emailVerified) {
                  openModal("VerifyEmailModal", "", {
                    userName: user?.displayName,
                    email: user?.email,
                  });
                }
              }}
              to={user?.emailVerified && "/chat"}
              className="nav-link p-0 position-relative "
            >
              {newMessagesCount > 0 && (
                <span
                  className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle ${
                    newMessagesCount > 9 ? "px-1" : ""
                  } bg-danger`}
                  style={{
                    top: "13%",
                    left: "99%",
                    fontSize: "10px",
                    minHeight: "7.5px",
                    minWidth: "7px",
                  }}
                >
                  {newMessagesCount}
                </span>
              )}
              <i
                className={`bi bi-chat-square-text${
                  location.pathname.includes("/chat") ? "-fill" : ""
                } fs-4`}
              ></i>
            </Link>
          </li>
          <li
            className="nav-item mx-0 mx-md-2 collapsible-nav-item"
            onClick={() => handleOutsideClick()}
          >
            <Link
              onClick={() => {
                if (!user?.emailVerified) {
                  openModal("VerifyEmailModal", "", {
                    userName: user?.displayName,
                    email: user?.email,
                  });
                }
              }}
              to={user?.emailVerified && "/shoppingLists"}
              className="nav-link p-0 shopping-list-link position-relative "
            >
              {totalShoppingListCount > 0 && (
                <span
                  className={`d-flex justify-content-center align-items-center position-absolute translate-middle rounded-circle badge bg-danger ${
                    totalShoppingListCount > 9 ? "px-1" : ""
                  }`}
                  style={{
                    top: "13%",
                    left: "99%",
                    fontSize: "10px",
                    minHeight: "7.5px",
                    minWidth: "7px",
                  }}
                >
                  {totalShoppingListCount}
                </span>
              )}
              <i
                className={`bi bi-bookmark${
                  location.pathname.includes("/shoppingLists") ? "-fill" : ""
                } fs-4`}
              ></i>
            </Link>
          </li>
          <li
            className="nav-item mx-0 mx-md-2 collapsible-nav-item"
            onClick={() => handleOutsideClick()}
          >
            <Link className="nav-link p-0 position-relative" to="/cart">
              {cartCount > 0 && (
                <span
                  className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle bg-danger ${
                    cartCount > 9 ? "px-1" : ""
                  }`}
                  style={{
                    top: "13%",
                    left: "99%",
                    fontSize: "10px",
                    minHeight: "7.5px",
                    minWidth: "7px",
                  }}
                >
                  {cartCount}
                </span>
              )}
              <i
                className={`bi bi-cart${
                  location.pathname.includes("/cart") ? "-fill" : ""
                } fs-4`}
              ></i>
            </Link>
          </li>
          <li className="nav-item dropdown userMenuHeader3" ref={dropdownRef}>
            <style jsx>
              {`
                .userMenuHeader3 .dropdown-toggle::after {
                  display: none;
                }
              `}
            </style>
            <Dropdown
              align="end"
              autoClose
              onToggle={() => {
                handleDropdownToggle();
                handleOutsideClick();
              }}
            >
              <Dropdown.Toggle
                variant="link"
                className="nav-link p-0 position-relative"
              >
                <i className={`bi bi-person fs-3`}></i>
                {newNotificationsCount > 0 && (
                  <span
                    className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle ${
                      newNotificationsCount > 9 ? "px-1" : ""
                    } bg-danger`}
                    style={{
                      top: "14%",
                      left: "90%",
                      fontSize: "10px",
                      minHeight: "7.5px",
                      minWidth: "7px",
                    }}
                  >
                    {newNotificationsCount}
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-animation pt-3 small me-md-n3"
                style={{ width: "350px" }}
              >
                <div className="px-1 " onClick={handleMenuItemClick}>
                  <Notification
                    user={user}
                    userData={userData}
                    newNotificationsCount={newNotificationsCount}
                  />
                </div>
                <Dropdown.Divider className="my-2" />
                <Dropdown.Item
                  as="div"
                  className="d-flex justify-content-center align-items-center p-0 w-100"
                >
                  <Dropdown.Item
                    as={Link}
                    to={`/userProfile/${user.uid}/shoppinglist`}
                    className="bg-primary-soft-hover ps-3 p-0 d-flex w-100"
                  >
                    <i className="bi bi-person fa-fw me-2"></i>
                    <div>Profile</div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="p-0 d-flex"
                    onClick={() => {
                      if (!user?.emailVerified) {
                        openModal("VerifyEmailModal", "", {
                          userName: user?.displayName,
                          email: user?.email,
                        });
                      }
                    }}
                  >
                    <ShareMenu />
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/SignIn"
                    className="bg-danger-soft-hover p-0 d-flex"
                    onClick={signOutUser}
                  >
                    <i className="bi bi-power fa-fw me-2"></i>
                    <div>Sign Out</div>
                  </Dropdown.Item>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  as="div"
                  className="modeswitch-item theme-icon-active d-flex justify-content-between gap-3 align-items-center pb-0 px-3"
                >
                  <Link
                    to={user?.emailVerified ? `/settings/accountSetting` : "#"}
                    onClick={() => {
                      if (!user?.emailVerified) {
                        openModal("VerifyEmailModal", "", {
                          userName: user?.displayName,
                          email: user?.email,
                        });
                      }
                    }}
                    className="d-flex gap-2 align-items-center nav-link p-0"
                  >
                    <Gear />
                    <div>Settings</div>
                  </Link>
                  <div className="vr border mx-1 nav-link p-0"></div>
                  <div
                    className="d-flex gap-2 align-items-center nav-link p-0"
                    style={{ cursor: "pointer" }}
                  >
                    <div className="me-2">Mode:</div>
                    <div className="d-flex gap-2">
                      <button
                        aria-label="Light Mode"
                        type="button"
                        className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                          theme === "light" ? "active text-white" : ""
                        }`}
                        onClick={() => {
                          handleTheme("light");
                          localStorage.setItem("theme", "light");
                        }}
                      >
                        <i className="bi bi-sun fa-fw"></i>
                      </button>
                      <button
                        aria-label="Dark Mode"
                        type="button"
                        className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                          theme === "dark" ? "active text-white" : ""
                        }`}
                        onClick={() => {
                          handleTheme("dark");
                          localStorage.setItem("theme", "dark");
                        }}
                      >
                        <i className="bi bi-moon-stars fa-fw"></i>
                      </button>
                      <button
                        aria-label="Auto Mode"
                        type="button"
                        className={`btn btn-modeswitch nav-link text-primary-hover mb-0 ${
                          theme === "" || theme === "auto"
                            ? "active text-white"
                            : ""
                        }`}
                        onClick={() => {
                          handleTheme("auto");
                          localStorage.setItem("theme", "auto");
                        }}
                      >
                        <i className="bi bi-circle-half fa-fw"></i>
                      </button>
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </>
      )} */}
    </>
  );
}

export default UserMenuHeader3;

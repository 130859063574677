import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Chat,
  Channel,
  MessageList,
  Window,
  Attachment,
  defaultRenderMessages,
  ChannelList,
} from "stream-chat-react";
import useChatClient from "../StatesStore/ChatClient";
import "stream-chat-react/dist/css/v2/index.css";
import { UserAuth } from "../Context";
import { Card, Nav } from "react-bootstrap";
import { EmojiPicker } from "stream-chat-react/emojis";
import { encodeToMp3 } from "stream-chat-react/mp3-encoder";
import "./Chat.css";
import SideBar from "./SideBar";
import ProductCard3 from "../Products/ProductCard3";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import { ChannelHeader } from "./ChannelHeader";
import CustomMessageInput from "./CustomMessageInput";

function ChatMobile() {
  const [loading, setLoading] = useState(true);
  const { user } = UserAuth();
  const navigate = useNavigate();
  const { chatId } = useParams();
  const client = useChatClient((state) => state.client);
  const [isChatOpen, setIsChatOpen] = useState(false);

  if (!client) {
    return <LoadingSpinner />;
  }

  const containerStyle = {
    display: "flex",
    height: "89vh",
    width: "100%",
    overflow: "hidden",
    gap: "10px",
  };

  const channelListStyle = {
    flex: 1,
    maxWidth: "300px",
    borderLeft: "1px solid rounded",
    overflowY: "auto",
    backgroundColor: "#f8f9fa",
    borderRadius: "4px 0 0 4px",
  };

  const channelWindowStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    borderRadius: "0px 4px 4px 0px",
  };
  const filters = {
    members: { $in: [user?.uid] },
    // type: 'messaging'
  };

  const sort = { last_message_at: -1 };

  const options = {
    state: true,
    presence: true,
    limit: 10,
  };
  const customRenderText = (text) => {
    return <span>{text}</span>;
  };

  const CustomAttachment = (props) => {
    const { attachments } = props;
    const [attachment] = attachments || [];
    if (attachment?.type === "product") {
      return <ProductCard3 product={attachment?.product} isStrip={false} />;
    }

    return <Attachment {...props} />;
  };
  const customRenderMessages = (options) => {
    const { messages, internalMessageProps, groupedMessages } = options;
    const elements = [];

    messages.forEach((message) => {
      if (message.product) {
        elements.push(
          <li key={message.id} className="w-25 h-25">
            <ProductCard3 product={message?.product} isStrip={false} />
          </li>
        );
      } else {
        elements.push(
          defaultRenderMessages({ ...options, messages: [message] })
        );
      }
    });

    return elements;
  };

  const WrappedMessageList = () => (
    <MessageList
      renderText={customRenderText}
      closeReactionSelectorOnClick={true}
    />
  );
  if (!user) return;
  return (
    <>
      <style>
        {`
        .str-chat {
   
          --str-chat__border-radius-circle: 4px; 
          --str-chat__avatar-background-color: #dddddd;
          --str-chat__avatar-color:#222222;
           --str-chat__channel-preview-active-background-color: transparent !important;
        }
           
      `}
      </style>
      <Chat client={client} theme="messaging light">
        <div style={containerStyle}>
          {!isChatOpen ? (
            <Card className="w-100 p-0 pb-2">
              <Card.Body
                className="rounded-2 p-0 h-100"
                onClick={() => setIsChatOpen(true)}
              >
                <div
                  className="chat-list-content h-100"
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <Nav className="d-flex flex-column nav-pills nav-pills-soft gap-4">
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <ChannelList
                        filters={filters}
                        sort={sort}
                        options={options}
                      />
                    </span>
                  </Nav>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <div style={channelWindowStyle}>
              <Channel EmojiPicker={EmojiPicker} Attachment={CustomAttachment}>
                <Window>
                  <ChannelHeader setIsChatOpen={setIsChatOpen} />
                  <MessageList
                    messageActions={["edit", "delete", "quote", "react"]}
                    closeReactionSelectorOnClick={true}
                  />
                  <CustomMessageInput
                    audioRecordingConfig={{
                      transcoderConfig: { encoder: encodeToMp3 },
                    }}
                    audioRecordingEnabled
                  />
                </Window>
              </Channel>
            </div>
          )}
        </div>
      </Chat>
    </>
  );
}

export default ChatMobile;

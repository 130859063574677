import React from "react";
import ShoppingListCard from "./ShoppingListCard";
import EmptyList from "./EmptyList";
import { useLocation, useParams } from "react-router-dom";
import { UserAuth } from "../Context";

const ProductList2 = ({
  shoppingListData,
  selectedShopListName,
  selectedShopListId,
  userId,
  role,
  ownerId,
  ownerName,
  notify,
  componentName,
  slidesToShow = 5,
}) => {
  const location = useLocation();
  const { user } = UserAuth();
  const { uid } = useParams();
  console.log("shoppingListData in product list", shoppingListData);
  const selectedShopListData = shoppingListData.find(
    (shopList) =>
      shopList.name === selectedShopListName &&
      shopList.listId === selectedShopListId
  );
  console.log("selectedShopListData in product list", selectedShopListData);

  return (
    <div
      className={`card ${
        componentName === "UserProfile" ? "px-3" : "px-0"
      } bg-transparent border border-0 mb-0`}
    >
      <div
        className="card-body p-0 bg-mode d-flex hiddenScrollbar"
        style={{ overflow: "auto", whiteSpace: "nowrap" }}
      >
        {shoppingListData.some(
          (shopList) =>
            shopList.name === selectedShopListName &&
            shopList.products.length > 0
        ) ? (
          <>
            {shoppingListData
              .filter(
                (shopList) =>
                  shopList.name === selectedShopListName &&
                  shopList.listId === selectedShopListId
              )
              .flatMap((shopList) =>
                shopList.products.map((product, index) => (
                  <ShoppingListCard
                    product={product}
                    listName={shopList.name}
                    shoppingListData={shoppingListData}
                    userId={userId}
                    role={role}
                    listId={selectedShopListId}
                    ownerId={ownerId}
                    key={index}
                    notify
                  />
                ))
              )}
          </>
        ) : (
          <div className="mx-auto" style={{ height: "24vh" }}>
            <EmptyList
              componentName={"shoppingList"}
              content={
                location.pathname.includes("inviteLists")
                  ? `${ownerName} haven't added any products in ${selectedShopListName} yet.`
                  : location.pathname.includes("shoppingLists") ||
                    location.pathname.includes("testShoppingLists")
                  ? `You haven't added any products in ${selectedShopListName} yet.`
                  : `${
                      uid === user?.uid ? "You" : ownerName
                    } haven't added any products in ${selectedShopListName}`
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductList2;

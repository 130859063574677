import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import {
  getDoc,
  doc,
  updateDoc,
  writeBatch,
  collection,
  deleteDoc,
  increment,
  serverTimestamp,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { toastOptions } from "../lib/toastOptions";
import useFavStore from "../StatesStore/FavStore";
import useModalStore from "../StatesStore/ModalStore";
import formatPrice from "../lib/formatPrice";
import useNotification from "../hooks/useNotification";
import useShoppingListProductsStore from "../StatesStore/ShoppingListProducts";
import AddToCart from "../components/AddToCart";
const ShoppingListCard = ({
  product,
  listName,
  shoppingListData,
  userId,
  listId,
  role,
  ownerId,
}) => {
  // console.log("ShoppingListCard", shoppingListData);
  // console.log("ownerId listName", ownerId, listName);
  // console.log("ShoppingListCard product", product);
  // console.log("ShoppingListCard ownerId", ownerId);
  // console.log("ShoppingListCard listId", listId);
  const [brandData, setBrandData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [hasLiked, setHasLiked] = useState(false);
  // const [hasLoadedBrandData, setHasLoadedBrandData] = useState(false);
  const { user } = UserAuth();
  const { openModal } = useModalStore();
  const removeFavoriteProduct = useFavStore((state) => state.removeFavorite);
  const { addNotification, deleteNotification } = useNotification();
  const setProductDataInStore = useShoppingListProductsStore(
    (state) => state.addProduct
  );

  const fetchBrandData = async () => {
    const brandRef = doc(db, "brands", productData?.supplier);
    try {
      const brandSnapshot = await getDoc(brandRef);
      if (brandSnapshot.exists()) {
        setBrandData(brandSnapshot.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
    // setHasLoadedBrandData(true);
  };
  useEffect(() => {
    const fetchProduct = async () => {
      if (product?.id) {
        const productRef = doc(db, "products", product?.id);
        const productSnapshot = await getDoc(productRef);
        if (productSnapshot.exists()) {
          const fetchedProductData = { ...productSnapshot.data(), ...product };
          setProductData({ ...productSnapshot.data(), ...product });
          setProductDataInStore(listName, fetchedProductData);
          const batch = writeBatch(db);
          const incrementViews = {
            views: productSnapshot.data()?.views
              ? productSnapshot.data()?.views + 1
              : 1,
          };
          batch.update(doc(db, "products", product?.id), incrementViews);
          batch.commit();
        }
      }
    };

    fetchProduct();
  }, [product?.id]);
  const handleTrashIconClick = async () => {
    if (!user) return;
    if (role === "viewer" || role === "commentor") {
      toast.warn(
        "You do not have permissions to delete any product.",
        toastOptions
      );
      return;
    }

    const userRef = doc(db, "users", userId);
    const shoppingListRef = collection(userRef, "shoppingList");

    try {
      const listDocRef = doc(shoppingListRef, listName);
      const listDoc = await getDoc(listDocRef);

      if (listDoc.exists()) {
        // Check visibility and user role
        if (shoppingListData?.[0].visibility === "Friends") {
          const userRole = shoppingListData?.[0]?.friends?.find(
            (friend) => friend.id === user?.uid
          )?.role;
          console.log("userRole", userRole);
          if (userRole !== "editor") {
            toast.error(
              "You do not have permission to delete this item.",
              toastOptions
            );
            return;
          }
        }

        // Handle product deletion from the 'products' subcollection
        const productRef = doc(listDocRef, "products", product?.id);

        // Check if the product document exists
        const productDoc = await getDoc(productRef);
        if (productDoc.exists()) {
          // Delete the product document from the 'products' subcollection
          await deleteDoc(productRef);

          // Update the inShoppingList entry
          const productInShoppingListRef = doc(
            db,
            "products",
            product.id,
            "inShoppingList",
            user.uid
          );
          const inShoppingListDoc = await getDoc(productInShoppingListRef);
          if (inShoppingListDoc.exists()) {
            const { listNames } = inShoppingListDoc.data();
            const newListNames = listNames.filter((name) => name !== listName);
            if (newListNames.length === 0) {
              // If no more lists contain this product, remove the document
              await deleteDoc(productInShoppingListRef);
            } else {
              // Otherwise, update the document with the new list of names
              await updateDoc(productInShoppingListRef, {
                listNames: newListNames,
              });
            }
          }

          // Decrement the shoppingListCount
          await updateDoc(userRef, {
            shoppingListCount: increment(-1),
          });

          if (listName === "Favorites") {
            removeFavoriteProduct(product?.id);
          }

          toast.success(
            `${productData?.name} removed from ${listName}`,
            toastOptions
          );
        } else {
          console.error("Product not found in the list.");
        }
      } else {
        console.error("List document does not exist.");
      }
    } catch (error) {
      console.error(
        "Error removing product from shopping list:",
        error.message
      );
    }
  };

  const handleClick = () => {
    const DATA = {
      deleteFunction: handleTrashIconClick,
      displayName: productData?.name,
      shoppingListName: listName,
    };
    openModal("ConfirmDelete", "shoppingListProduct", DATA);
  };
  useEffect(() => {
    if (productData) fetchBrandData();
  }, [productData]);

  const handleLike = async (productId) => {
    if (!user) {
      openModal("SignInModal");
      return;
    } else if (!user?.emailVerified) {
      openModal("VerifyEmailModal", "", {
        userName: user?.displayName,
        email: user?.email,
      });
      return;
    }

    // Navigate to the product within the user's shopping list
    const userRef = doc(db, "users", ownerId);
    const shoppingListRef = collection(userRef, "shoppingList");
    const productRef = doc(shoppingListRef, listName, "products", productId);

    const likesRef = collection(productRef, "likes");
    const userLikeRef = doc(likesRef, user?.uid);

    try {
      const userLikeSnapshot = await getDoc(userLikeRef);

      const batch = writeBatch(db);

      if (!userLikeSnapshot.exists()) {
        // User has not liked the product, add like
        setProductData((prev) => ({
          ...prev,
          likeCount: prev?.likeCount + 1,
        }));
        setHasLiked(true);
        batch.set(userLikeRef, {
          timestamp: serverTimestamp(),
        });

        // Increment likeCount in the product document
        batch.update(productRef, {
          likeCount: increment(1),
        });
        console.log("from shoppinglist card inside before addNotification");
        if (user?.uid !== ownerId) {
          console.log("from shoppinglist card inside addNotification");
          console.log("userId" + ownerId + "listId" + listId);

          await addNotification({
            userId: ownerId,
            id: user?.uid,
            type: "listProductLike",
            by: "user",
            fields: {
              productId: productId,
              shopList: listName,
              listId: listId,
            },
          });
        }

        // toast.success("Product liked!", toastOptions);
      } else {
        setProductData((prev) => ({
          ...prev,
          likeCount: prev?.likeCount - 1,
        }));
        setHasLiked(false);
        // User has already liked the product, remove like
        batch.delete(userLikeRef);

        // Decrement likeCount in the product document
        batch.update(productRef, {
          likeCount: increment(-1),
        });

        if (user?.uid !== ownerId) {
          await deleteNotification({
            userId: ownerId,
            id: user?.uid,
            type: "listProductLike",
            by: "user",
            fields: {
              productId: productId,
              shopList: listName,
              listId: listId,
            },
          });
        }

        // toast.info("Product unliked!", toastOptions);
      }

      await batch.commit();
    } catch (error) {
      console.error("Error updating like status:", error);
      toast.error("Failed to update like status.", toastOptions);
    }
  };
  useEffect(() => {
    const checkIfLiked = async () => {
      if (user && productData?.id) {
        const userRef = doc(db, "users", ownerId);
        const shoppingListRef = collection(userRef, "shoppingList");
        const productRef = doc(
          shoppingListRef,
          listName,
          "products",
          productData?.id
        );
        const likesRef = collection(productRef, "likes");
        const userLikeRef = doc(likesRef, user?.uid);
        const userLikeSnapshot = await getDoc(userLikeRef);

        if (userLikeSnapshot.exists()) {
          setHasLiked(true); // User has already liked the product
        } else {
          setHasLiked(false);
        }
      }
    };

    checkIfLiked();
  }, [user, productData?.id]);
  return (
    product && (
      <div
        className="d-flex flex-column ms-2 ms-md-3 mt-2 pe-0 pb-0"
        style={{ width: "7rem" }}
      >
        <Card className="bg-transparent border-0" style={{ width: "7rem" }}>
          <Link to={`/ProductDetails/${productData?.id}`}>
            <Card.Img
              variant="top"
              className="rounded"
              src={productData?.imageUrl}
              style={{
                // height: `${randomNumberInRange(280, 200)}px`,
                objectFit: "contain",
                height: "10rem",
              }}
            />

            <div
              className=" text-white position-absolute rounded-pill px-2 py-1"
              style={{
                top: "3%",
                right: "5%",
                fontSize: "10px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <AddToCart user={user} product={productData} />
            </div>
            <div
              className=" text-white position-absolute rounded-pill px-2"
              style={{
                top: "3%",
                left: "5%",
                fontSize: "10px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              PKR {formatPrice(productData?.newPrice)}
            </div>
            <div
              className="position-absolute d-flex w-100"
              style={{
                bottom: "1%",
                right: "3%",
              }}
            >
              <span className="badge ms-auto px-1 rounded text-white small">
                <Link
                  to=""
                  className="icon-sm text-white rounded-circle"
                  onClick={() => handleClick()}
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  <i className="fs-6 bi bi-trash"></i>
                </Link>
              </span>
            </div>

            {/* strip */}

            <div
              className="d-inline-flex flex-column align-items-center justify-content-evenly bg-mode rounded-pill py-1 px-1 text-white position-absolute m-0"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                bottom: "3%",
                left: "5%",
              }}
              id="hoverStrip"
            >
              <Link
                className="text-center d-flex align-items-center text-white"
                onClick={() => handleLike(productData?.id)}
              >
                <i
                  className={`bi ${
                    hasLiked ? "bi-hand-thumbs-up-fill" : "bi-hand-thumbs-up"
                  }`}
                ></i>
                <div
                  style={{
                    fontSize: "10px",
                  }}
                  className=" mx-1"
                >
                  {" "}
                  {productData?.likeCount}
                </div>
              </Link>
            </div>
          </Link>
        </Card>
        <div className="d-flex justify-content-start align-items-center mb-1 mt-1 pe-1">
          <div
            className="avatar me-1"
            style={{ width: "25px", height: "25px" }}
          >
            <Link to={"/BrandHome/" + productData?.supplier}>
              <img
                className="avatar-img rounded"
                src={brandData?.logoUrl}
                alt=""
              />
            </Link>
          </div>
          <div
            className="fw-bold h6 position-relative"
            style={{
              marginTop: "5%",
              fontSize: "12px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {productData?.name}
          </div>
        </div>
      </div>
    )
  );
};

export default ShoppingListCard;

import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import {
  getDoc,
  doc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import ProfilePicture from "./ProfilePicture";
import useModalStore from "../StatesStore/ModalStore";
import useNotification from "../hooks/useNotification";
import UpdatePFP from "./UpdatePFP";
import useFeedNotification from "../hooks/useFeedNotification";
import ChatToast from "../Chat/ChatToast";
import useSocketStore from "../StatesStore/Socket";
import { Button, Card, Modal } from "react-bootstrap";
import useFollowUser from "../hooks/useFollowUser";
import { HandleUnfriend } from "./ActiveConnections";
import {
  HandleAcceptConnectionReq,
  HandleRejectConnectionReq,
} from "./ConnectionRequest";
import SendFriendRequest from "./SendConnectionRequest";
import handlefollowUser from "../hooks/user/followUser";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";

const UserProfileHeader3 = ({ selectedOption, setSelectedOption }) => {
  const { uid } = useParams(); // Extract the "uid" parameter from the URL
  const [userData, setUserData] = useState(null);
  const { user } = UserAuth();
  const [show, setShow] = useState(false);
  const socket = useSocketStore((state) => state.socket);
  const [friendUid, setfriendUid] = useState(uid);
  const [activeList, setActiveList] = useState([]);
  const [pendinglist, setPendingList] = useState([]);
  const [requestlist, setRequestList] = useState([]);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const { openModal } = useModalStore();
  const { addNotification, deleteNotification } = useNotification();
  const { addFeedNotification } = useFeedNotification();
  // State to track if profile picture is updated
  const [profilePictureUpdated, setProfilePictureUpdated] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { isFollowed, setIsFollowed, isFollowBack, followUser } = useFollowUser(
    user?.uid,
    uid
  );
  const handleClose = () => setShow(false);
  const handleCancel = async () => {
    await HandleRejectConnectionReq(friendUid, user?.uid);
    setPendingList(pendinglist.filter((id) => id !== uid));
    handleClose();
    toast.success("Request removed successfully!", toastOptions);
  };
  const handleAccept = async () => {
    await HandleAcceptConnectionReq(friendUid, user?.uid);
    setPendingList(pendinglist.filter((id) => id !== uid));
    setActiveList([...activeList, uid]);
    handleClose();
    toast.success("Request accepted successfully!", toastOptions);
  };

  const handleChatClick = (e) => {
    e.preventDefault();
    setIsChatOpen(true);
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };
  // console.log("userData from profile", userData);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (uid) {
          try {
            const userDocRef = doc(db, "users", uid); // Fetch user data
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists()) {
              setUserData({
                uid: userDocSnapshot.id,
                ...userDocSnapshot.data(),
              });
              if (user) {
                // Fetch connections for the user
                const connectionsRef = collection(
                  db,
                  "users",
                  user?.uid,
                  "connections"
                );
                const connectionsSnapshot = await getDocs(connectionsRef);

                // Filter connections by type
                const activeConnections = [];
                const pendingRequests = [];
                const requestList = [];

                connectionsSnapshot.forEach((doc) => {
                  const connectionData = doc.data();
                  if (connectionData.type === "active") {
                    activeConnections.push(doc.id);
                  } else if (connectionData.type === "pending") {
                    pendingRequests.push(doc.id);
                  } else if (connectionData.type === "request") {
                    requestList.push(doc.id);
                  }
                });

                setActiveList(activeConnections);
                setPendingList(pendingRequests);
                setRequestList(requestList);
              }

              const followerRef = collection(userDocRef, "followers");
              const followerSnap = await getDocs(followerRef);
              setFollowerCount(followerSnap.size || 0);
              const followingRef = collection(userDocRef, "following");
              const followingQuery = query(
                followingRef,
                where("type", "==", "user")
              );
              const followingDocs = await getDocs(followingQuery);
              setFollowingCount(followingDocs.size);
            } else {
              console.log("User document does not exist.");
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          console.log("No uid provided in the URL.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUserData();
  }, [user, uid, profilePictureUpdated]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.uid) {
        console.error("User ID is undefined or null");
        return;
      }
      const checkBrandSnapshot = doc(
        db,
        "users",
        user?.uid,
        "following",
        friendUid
      );
      const checkBrandData = await getDoc(checkBrandSnapshot);
      if (checkBrandData.exists()) setIsFollowed(true);
      else setIsFollowed(false);
    };
    fetchData();
  }, [userData]);
  //to get screen widths adjusted in avatar
  console.log("pendinglist", pendinglist);

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) {
      return "N/A";
    }

    const options = { year: "numeric", month: "short", day: "numeric" };
    return timestamp.toDate().toLocaleDateString("en-US", options);
  };

  const isCurrentUserProfile = user?.uid === uid;

  // console.log('Request list fetched in userprofile header',pendinglist);
  // console.log('loggedinUser document from userprofileheader',loggedinUserDoc);
  // console.log('UserData inside userprofileheader',userData);
  //a

  const handleClick = () => {
    if (!user) {
      openModal("SignInModal", "", "");
    } else if (!user?.emailVerified) {
      openModal("VerifyEmailModal", "", {
        userName: user?.displayName,
        email: user?.email,
      });
    }
  };
  const handleClickUnfollow = () => {
    const DATA = {
      displayName: userData.userName,
      FollowButton: setIsFollowed,
      UID: user?.uid,
      friendUID: friendUid,
    };
    openModal("ConfirmUnfollow", "user", DATA); // Open modal here
  };
  const handleClickUnfriend = async () => {
    const DATA = {
      titleContent: "Confirm Unfriend?",
      content: "Are you sure you want to unfriend " + userData?.userName,
      buttonContent: "Unfriend",
      type: "Unfriend",
      UID: user?.uid,
      friendUID: friendUid,
      yesFunction: HandleUnfriend,
      setActiveList,
      activeList,
    };
    openModal("ConfirmModal", "User", DATA); // Open modal here
  };
  const handleProfilePictureUpdate = () => {
    // Toggle the state to trigger re-render
    setProfilePictureUpdated(!profilePictureUpdated);
  };
  const userProfilePicture = () => {
    return (
      <div
        className="avatar avatar-xxl userImage"
        style={{
          width: "9rem", // Fixed width
          height: "9rem", // Fixed height
          overflow: "hidden", // Hide overflow to maintain square shape
        }}
      >
        {userData && isCurrentUserProfile ? (
          <UpdatePFP
            Uid={user?.uid}
            userData={userData}
            avatarSize="xxl"
            className=""
            style={{
              objectFit: "cover", // Cover the entire container area
              objectPosition: "center", // Center the image within the div
            }}
            onProfilePictureUpdate={handleProfilePictureUpdate}
          />
        ) : (
          <ProfilePicture userData={userData} />
        )}
      </div>
    );
  };
  const userDetailsAndFollowButton = () => {
    return (
      <>
        {/* <div className="userDetailsAndFollowButton d-flex flex-row justify-content-between align-items-center w-100 flex-wrap"> */}
        <div>
          <div className="userName h3 fw-semibold m-0 p-0">
            {userData
              ? userData.userName
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              : ""}
          </div>
          <ul
            className="navbar nav align-items-start justify-content-center justify-content-sm-start p-0 m-0 border-0 h6 gap-3"
            style={{ whiteSpace: "nowrap" }}
          >
            {/* User Connections */}
            {/* <li className="nav-item">
              <NavLink
                className={`nav-link p-0 ${
                  selectedOption === "friends" ? "active" : ""
                }`}
                to={`/userProfile/${uid}/network/userFriends`}
                onClick={() => setSelectedOption("friends")}
              >
                {activeList && activeList.length > 0
                  ? `${activeList.length} Friend${
                      activeList.length > 1 ? "s" : ""
                    }`
                  : "0 Friend"}
              </NavLink>
            </li> */}
            {/* Follower Count */}
            <li className="nav-item">
              <NavLink
                className={`nav-link p-0 ${
                  selectedOption === "follower" ? "active" : ""
                }`}
                to={`/userProfile/${uid}/network/userfollowers`}
                onClick={() => setSelectedOption("follower")}
              >
                {followerCount} Followers
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link p-0 ${
                  selectedOption === "following" ? "active" : ""
                }`}
                to={`/userProfile/${uid}/network/userfollowing`}
                onClick={() => setSelectedOption("following")}
              >
                {followingCount} Following
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="followButton d-flex gap-2 mt-2 mt-sm-0">
          {!isCurrentUserProfile &&
            userData &&
            (userData.privacySetting === undefined ||
              userData.privacySetting.message === true) && (
              <button
                className="toast-btn btn btn-primary"
                type="button"
                data-target="chatToast"
                onClick={user?.emailVerified ? handleChatClick : handleClick}
              >
                <i className="bi bi-chat-left-text-fill fs-7"></i>
              </button>
            )}
          {!isCurrentUserProfile &&
            userData &&
            (userData.privacySetting === undefined ||
              userData.privacySetting.friendRequest === true) && (
              <button
                className={`btn btn-${
                  (activeList && activeList.includes(uid)) ||
                  (pendinglist && pendinglist.includes(uid)) ||
                  (requestlist && requestlist.includes(uid))
                    ? "danger"
                    : "primary"
                }`}
                type="button"
                onClick={async () => {
                  if (!user) {
                    handleClick();
                  } else if (!user?.emailVerified) {
                    openModal("VerifyEmailModal", "", {
                      userName: user?.displayName,
                      email: user?.email,
                    });
                  } else if (user) {
                    if (activeList && activeList.includes(uid)) {
                      await handleClickUnfriend();
                    } else if (pendinglist && pendinglist.includes(uid)) {
                      setShow(true);
                    } else if (requestlist && requestlist.includes(uid)) {
                      await HandleRejectConnectionReq(friendUid, user?.uid);
                      setRequestList(requestlist.filter((id) => id !== uid));
                    } else {
                      await SendFriendRequest(user?.uid, friendUid);
                      setRequestList([...requestlist, uid]);
                      if (!isFollowed && !isFollowBack) {
                        await handlefollowUser(user?.uid, friendUid);
                      }
                      toast.success(
                        "Friend request has been sent",
                        toastOptions
                      );
                    }
                  }
                }}
              >
                {activeList && activeList.includes(uid) ? (
                  <>
                    <i className="bi bi-person-dash"></i>
                    {" Unfriend "}
                  </>
                ) : pendinglist && pendinglist.includes(uid) ? (
                  <>
                    <i className="bi bi-person-plus"></i>
                    {" Respond"}
                  </>
                ) : requestlist && requestlist.includes(uid) ? (
                  <>
                    <i className="bi bi-person-dash"></i>
                    {" Cancel Request"}
                  </>
                ) : (
                  <>
                    <i className="bi bi-person-dash"></i>
                    {" Add Friend"}
                  </>
                )}
              </button>
            )}
          {!isCurrentUserProfile && (
            <button
              className={`btn btn-primary`}
              onClick={
                user?.emailVerified
                  ? isFollowed
                    ? handleClickUnfollow
                    : followUser
                  : handleClick
              }
            >
              {isFollowed
                ? "Unfollow"
                : isFollowBack
                ? "Follow Back"
                : "Follow"}
            </button>
          )}
        </div>
        {/* </div> */}
      </>
    );
  };

  // useEffect(() => {
  //   setSelectedOption("");
  // }, [selectedOption]);

  return (
    <>
      <ChatToast
        isOpen={isChatOpen}
        onClose={handleCloseChat}
        userData={userData}
        user={user}
        uid={uid}
        socket={socket}
      />
      <Card className="bg-transparent border-0">
        <Card.Body className="p-0 mb-3">
          {/* Mobile View */}
          <div className="d-flex d-sm-none flex-wrap justify-content-center">
            {/* Profile Picture */}
            {userProfilePicture()}
            {/* User Details and Buttons */}
            <div className="userDetailsAndFollowButton d-flex flex-wrap flex-column justify-content-center align-items-center mt-2 text-center w-100">
              {userDetailsAndFollowButton()}
            </div>
          </div>
          {/* Desktop View */}
          <div className="d-none d-sm-flex gap-3">
            {/* Profile Picture */}
            {userProfilePicture()}
            {/* User Details and Buttons */}
            <div className="userDetailsAndFollowButton d-flex flex-wrap flex-column justify-content-between align-items-start mt-5 w-100">
              {userDetailsAndFollowButton()}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card.Footer className="mb-2  py-1 border rounded-2 stickyHeader bg-mode">
        <ul
          className={`navbar nav align-items-start justify-content-center justify-content-md-center py-2 border-0 h6`}
        >
          <li className="nav-item">
            <NavLink
              to={`/userProfile/${uid}/shoppinglist`}
              className={`nav-link ${
                selectedOption === "shoppinglist" ? "active" : ""
              }`}
              onClick={() => setSelectedOption("shoppinglist")}
            >
              Shopping List
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={`/userProfile/${uid}/posts`}
              className={`nav-link ${
                selectedOption === "posts" ? "active" : ""
              }`}
              onClick={() => setSelectedOption("posts")}
            >
              Posts
            </NavLink>
          </li>
        </ul>
      </Card.Footer>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Respond</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <i
            style={{ fontSize: "4.5rem" }}
            className="text-body bi bi-person-plus "
          ></i>
          <div className="mt-4 fs-5 text-body">
            {userData &&
              userData?.userName
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}{" "}
            wants to add you as a friend
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="danger-soft"
            className="w-25 mx-1"
            onClick={handleCancel}
          >
            Reject
          </Button>
          <Button
            variant="primary"
            className="w-25 mx-1"
            onClick={handleAccept}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserProfileHeader3;
